import axiosInstance from "./axiosConfig";

export const getTopicList = (query: string) => {
    return axiosInstance.get(`/api/admin/topics${query ?? ""}`);
};

export const filterTopicList = (query: string) => {
    return axiosInstance.get(
        `/api/admin/topics/filter${query ?? ""}`
    );
};

export const getTopicDetails = (id: string) => {
    return axiosInstance.get(`/api/admin/topic/${id}`);
};

export const createTopic = (data: any) => {
    return axiosInstance.post(`/api/admin/create-topic`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};

export const updateTopic = (data: any) => {
    return axiosInstance.post(`/api/admin/update-topic`, data, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
