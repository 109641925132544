const images = {
    logo_lotte: require("./logo_lotte.jpg"),
    logo: require("./logo.svg").default,
    placeholder: require("./evsis.png"),
    connect: require("./connect.png"),
    error403: require("./403.png"),
    error404: require("./404.png"),

};

export default images;
