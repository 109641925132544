import React from "react";

import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useApp } from "../../../hooks/useApp";
import { createUser } from "../../../services/userService";
import { FORMAT_EMAIL, LABEL, MESSAGE } from "../../constants";
import { PATH } from "../constants";

const UserNew: React.FC<any> = () => {
    const app = useApp()

    const navigate = useNavigate();

    const { handleSubmit, control} = useForm({
        defaultValues: {
            email: "",
            fullName: "",
        },
    });

    const submit = (values: any) => {
        let _email = values?.email || ''
        let _fullName = values?.fullName?.trim() || ''

        if (!_email) {
            app.showToast({
                type: 'error',
                message: MESSAGE.validate.required.replace("{field}", LABEL.common.email)
            })
            return;
        }

        if (_email && !FORMAT_EMAIL.test(_email)) {
            app.showToast({
                type: 'error',
                message: MESSAGE.format.email
            })
            return;
        }

        if (_fullName && _fullName?.length > 255) {
            app.showToast({
                type: 'error',
                message: MESSAGE.validate.maxLengthFullName
            })
            return;
        }

        createUser(values)
            .then((res) => {
                if (res?.status === 200) {
                    app.showToast({
                        type: 'success',
                        message: res.data.message
                    })
                    navigate(PATH.index);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    };

    return (
        <>
            <Fieldset legend={LABEL.common.addNew} className="w-fieldset">
                <form onSubmit={handleSubmit(submit)} className="flex flex-column align-items-center gap-5">
                    <div className="flex flex-column gap-5 lg:col-6 md:col-10 sm:col-12">
                        {/* Email */}
                        <div className="flex align-items-center gap-2 w-full">
                            <label
                                htmlFor="email"
                                className="min-w-label-3"
                            >
                                {LABEL.common.email}
                                <span className="text-red-600 pl-1">*</span>
                            </label>
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                    />
                                )}
                            />
                        </div>
                        {/* Email */}

                        {/* Fullname */}
                        <div className="flex align-items-center gap-2 w-full">
                            <label
                                htmlFor="fullName"
                                className="min-w-label-3"
                            >
                                {LABEL.common.fullName}
                            </label>
                            <Controller
                                name="fullName"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            className={"w-full"}
                                        />
                                )}
                            />
                        </div>
                        {/* Fullname */}

                    </div>

                    <div className="flex justify-content-end gap-5 lg:col-6 md:col-10 sm:col-12">
                        <Link to={PATH.index}>
                            <Button
                                label={LABEL.common.cancel}
                                type="button"
                                severity="secondary"
                            />
                        </Link>
                        <Button label={LABEL.common.save} type="submit" />
                    </div>
                </form>
            </Fieldset>
        </>
    );
};

export default UserNew;
