import React, {
    useEffect,
    useState
} from "react";

import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { getContactDetails, updateContact } from "../../../services/contactService";
import { BUILDING_TYPE_OPTION, FORM_TYPE_OPTION, PATH } from "../constants";

import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useApp } from "../../../hooks/useApp";
import { Countries } from "../../../jsons/country";
import { ContactDetailsType } from "../../../types/ContactTypes";
import { CountryType } from "../../../types/Layout";
import { FORMAT_EMAIL, LABEL, MESSAGE, FORMAT_MOBILE_PHONE, FORMAT_TELEPHONE } from "../../constants";

const ContactDetails: React.FC<any> = () => {
    const { id } = useParams<string>()

    const navigate = useNavigate();

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            id: "",
            language: "",
            formType: null,
            company: "",
            email: "",
            companyAddress: "",
            fullName: "",
            telephone: "",
            mobiphone: "",
            passCode: "",
            expectedQuantity: "",
            buildingType: null,
            preInstallQuantity: "",
            parkingStatus: "",
            comment: "",
            position: "",
            country: null,
            regId: "",
            regDTime: "",
            updId: "",
            updTime: "",
            attachment: ''
        },
    });

    const [data, setData] = useState<ContactDetailsType>({} as ContactDetailsType);
    const [isEdit, setEdit] = useState<boolean>(false);

    const [countriesOption] = useState<any>(() => {
        return Countries
    });
    const [filteredCountries, setFilteredCountries] = useState([]);

    const app = useApp()

    const enableEdit = () => {
        setEdit(true)
        app.showToast({ message: MESSAGE.enableEdit, type: 'success' });
    }

    const disableEdit = () => {
        setEdit(false)
    }

    const onBack = () => {
        if (isEdit) {
            disableEdit()
        }
        else {
            navigate(PATH.index)
        }
    }

    const submit = (values: any) => {
        let newCountry = values?.country?.value || ''

        let newValues = {
            ...values,
            formType: values?.formType?.code,
            buildingType: values?.buildingType?.code,
            country: newCountry
        }

        let mobi = newValues?.mobiphone
        let tel = newValues?.telephone
        let email = newValues?.email

        if (mobi && !FORMAT_MOBILE_PHONE.test(mobi)) {
            app.showToast({
                message: MESSAGE.format.mobilePhone,
                type: 'error'
            });
            return;
        }

        if (tel && !FORMAT_TELEPHONE.test(tel)) {
            app.showToast({
                message: MESSAGE.format.telephone,
                type: 'error'
            });
            return;
        }

        if (email && !FORMAT_EMAIL.test(email)) {
            app.showToast({
                message: MESSAGE.format.email,
                type: 'error'
            });
            return;
        }

        updateContact(newValues)
            .then((res) => {
                const status = res.status;
                if (status === 200) {
                    app.showToast({
                        type: 'success',
                        message: res.data.message,
                    });
                    navigate(PATH.index);
                }
            })
            .catch((error: any) => {
                let message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message,
                });
            })
    }

    useEffect(() => {
        if (id) {
            getContactDetails(id)
                .then((res) => {
                    if (res?.status === 200) {
                        setData(res.data);
                    }
                })
                .catch((error: any) => {
                    let message = error?.response?.data?.message || error?.message;
                    app.showToast({
                        type: 'error',
                        message: message,
                    });
                })
        }
    }, [id]);

    const createInitData = (data: any) => {
        const _initData = {
            ...data
        }
        if (data.formType) {
            const _formType = FORM_TYPE_OPTION.find((option) => { return option?.code === data.formType })
            _initData.formType = _formType
        }

        if (data.buildingType) {
            const _buildingType = BUILDING_TYPE_OPTION.find((option) => { return option?.code === data.buildingType })
            _initData.buildingType = _buildingType
        }

        if (data.country) {
            const _country = countriesOption?.find((country: CountryType) => { return country?.value === data?.country })
            _initData.country = _country
        }
        return _initData
    }

    // set initial values
    useEffect(() => {
        if (Object.keys(data).length) {
            const _initData = createInitData(data)
            setValue("id", id ?? '');
            Object.keys(_initData)?.forEach((key: string) => {
                const _data = _initData[key]
                const _key = key as any
                setValue(_key, _data ?? "");
            })
        }
    }, [data, id, setValue]);

    const openLinkAttachment = (link: string) => (e: any) => {
        if (link) {
            window.open(link, "_self")
        }
    }

    const search = (event: any) => {
        setTimeout(() => {
            let _filteredCountries;

            if (!event?.query?.trim().length) {
                _filteredCountries = [...countriesOption];
            }
            else {
                _filteredCountries = countriesOption?.filter((country: any) => {
                    return country?.label.toLowerCase()?.includes(event?.query?.toLowerCase());
                });
            }
            setFilteredCountries(_filteredCountries);
        }, 250);
    }

    return (
        <>
            <Fieldset legend={LABEL.common.detail} className="w-fieldset">
                <div className="flex justify-content-end mb-3 gap-3">
                    <Button
                        label={LABEL.common.edit}
                        icon="pi pi-pencil"
                        type="button"
                        onClick={enableEdit}
                        disabled={isEdit}
                    />
                </div>

                <form onSubmit={handleSubmit(submit)} className="flex flex-column gap-3">
                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* id */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="id"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.common.id}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled
                                        />
                                    </>
                                )}
                            />
                        </div>

                        {/* formType */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="formType"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.formType}
                                        </label>
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={FORM_TYPE_OPTION}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className={"w-full"}
                                            disabled
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* company */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="company"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.company}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        {/* country */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="country"
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.country}
                                        </label>
                                        <AutoComplete
                                            field="label"
                                            dropdown
                                            value={value}
                                            suggestions={filteredCountries}
                                            completeMethod={search}
                                            onChange={(e) => onChange(e?.value)}
                                            className="w-full"
                                            inputClassName="w-full"
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* fullname */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="fullName"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.fullName}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        {/* email */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.common.email}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>

                    </div>

                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* telephone */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="telephone"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.telephone}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        {/* mobile */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="mobiphone"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.mobilePhone}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* position */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="position"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.position}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            {/* address */}
                            <Controller
                                name="companyAddress"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.common.address}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* building type */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="buildingType"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.buildingType}
                                        </label>
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={BUILDING_TYPE_OPTION}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        {/* expection */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="expectedQuantity"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.expectedQuantity}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap align-items-center gap-5">
                        {/* parking status */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="parkingStatus"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.parkingStatus}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        {/* pre-install  */}
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            <Controller
                                name="preInstallQuantity"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.preInstallQuantity}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                            disabled={!isEdit}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {
                        data?.attachment &&
                        <div className="flex flex-wrap align-items-center gap-5">
                            {/* attachment */}
                            <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                                <Controller
                                    name="attachment"
                                    control={control}
                                    render={({
                                        field: { name, value, onBlur, onChange },
                                    }) => (
                                        <>
                                            <label className="min-w-label-3">
                                                {LABEL.contact.attachment}
                                            </label>

                                            <div
                                                onClick={openLinkAttachment(value)}
                                                className="w-full overflow-hidden white-space-nowrap text-overflow-ellipsis cursor-pointer underline text-blue-500"
                                                style={{ width: 'calc(100% - 10rem)' }}
                                            >
                                                {value}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    }

                    <div className="flex flex-wrap align-items-start gap-3">
                        <div className="flex align-items-center gap-2 lg:col-5 md:col-8 sm:col-12">
                            {/* comment */}
                            <Controller
                                name="comment"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-3"
                                        >
                                            {LABEL.contact.comment}
                                        </label>
                                        <InputTextarea
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            disabled={!isEdit}
                                            className="flex-grow-1"
                                            rows={5}
                                        />
                                    </>
                                )}
                            />
                        </div>

                    </div>

                    {
                        isEdit ?
                            <div className="flex justify-content-end gap-3">
                                <Button
                                    label={LABEL.common.cancel}
                                    type="button"
                                    severity="secondary"
                                    onClick={disableEdit}
                                />
                                <Button label={LABEL.common.save} type="submit" />
                            </div>
                            :
                            <div className="flex justify-content-end gap-3">
                                <Button
                                    label={LABEL.common.back}
                                    type="button"
                                    severity="secondary"
                                    onClick={onBack}
                                    icon='pi pi-angle-double-left'
                                />
                            </div>
                    }
                </form>
            </Fieldset>
        </>
    );
};

export default ContactDetails;
