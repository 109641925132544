import React, { useCallback } from "react";

import { QAType } from "../../../types/QATypes";
import { DATE, PATH } from "../constants";

import { format } from "date-fns";

import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import DetailBtn from "../../../components/common/DetailBtn";
import { LABEL } from "../../constants";

interface ListProps {
    data: QAType[];
    pagingSpec: any;
}

const QAList: React.FC<ListProps> = ({ data, pagingSpec }) => {

    const createDate = useCallback((date: string) => {
        if (!date) return null;

        const _date = new Date(
            date.replace(
                /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
                "$4:$5:$6 $2/$3/$1"
            )
        );
        return format(_date, DATE.default);
    }, []);

    const createStatus = useCallback((status: string) => {
        if (!status) return null;

        let type: "warning" | "success" = "warning";
        let _status = "문의완료";
        if (status === "REPLIED") {
            type = "success";
            _status = "답변완료";
        }

        return <Tag severity={type} value={_status} />;
    }, []);

    const createPrivate = useCallback((hidden: string) => {
        if (!hidden) return null;

        let type: "warning" | "success" = "warning";
        if (hidden === "Y") {
            type = "success";
        }

        return <Tag severity={type} value={hidden} />;
    }, []);

    const renderDetailBtn = (data: QAType) => {
        return <DetailBtn path={PATH.details} param={data?.qnaId} />
    }

    return (
        <DataTable value={data}>
            <Column
                header="#"
                body={(data: QAType, props: ColumnBodyOptions) => {
                    const index = props.rowIndex;
                    const page = pagingSpec.page;
                    const size = pagingSpec.size;

                    return page * size + index + 1;
                }}
            />
            <Column header="Q&A번호" field="qnaId"/>
            <Column field="subject" header="제목" />
            <Column
                header="진행상태"
                align={"center"}
                body={(data: QAType) => {
                    return createStatus(data.status);
                }}
            />
            <Column
                header="비공개"
                align={"center"}
                body={(data: QAType) => {
                    return createPrivate(data.hidden);
                }}
            />

            <Column
                header="작성일"
                align={"center"}
                body={(data: QAType) => {
                    return createDate(data.regDTime);
                }}
            />

            <Column
                header={LABEL.common.updateDate}
                align={"center"}
                body={(data: QAType) => {
                    return createDate(data.updTime);
                }}
            />
            <Column field="regId" header="회원번호" />
            <Column field="updId" header="수정자" />

            <Column
                header={LABEL.common.addDetail}
                align={"center"}
                body={(data: any) => { return renderDetailBtn(data) }}
            />
        </DataTable>
    );
};

export default QAList;
