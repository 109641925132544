import axiosInstance from "./axiosConfig";

export const getQAList = (query: string) => {
    return axiosInstance.get(`/api/admin/qnas${query ?? ""}`);
};

export const filterQAList = (query: string) => {
    return axiosInstance.get(
        `/api/admin/qnas/filter${query ?? ""}`
    );
};

export const getQADetails = (id: string) => {
    return axiosInstance.get(`/api/admin/qna/${id}`);
};

export const updateQA = (data: any) => {
    return axiosInstance.put("/api/admin/qna", data);
};

