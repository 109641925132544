const HeaderEditor = () => {
  return (
    <span className="ql-formats">
    <select className="ql-header"></select>
    <select className="ql-font"></select>
    <button className="ql-bold" aria-label="Bold"></button>
    <button className="ql-italic" aria-label="Italic"></button>
    <button className="ql-underline" aria-label="Underline"></button>
    <select className="ql-color"></select>
    <select className="ql-background"></select>
    <button
      className="ql-list"
      value="ordered"
      aria-label="Ordered List"
    ></button>
    <button
      className="ql-list"
      value="bullet"
      aria-label="Unordered List"
    ></button>
    <select className="ql-align"></select>
    <button className="ql-link"></button>
    <button className="ql-image"></button>
    <button className="ql-code-block"></button>
    <button className="ql-clean"></button>
    <button className="ql-video"></button>
  </span>
  );
};

export default HeaderEditor;

