import { LABEL } from "../constants";

export const PATH = {
    index: "/job",
    details: "/job/details",
};

export const STATUS_OPTION = [
    { name: LABEL.common.active, code: "ACTIVE" },
    { name: LABEL.common.inActive, code: "IN_ACTIVE" },
];
