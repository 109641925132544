import React, { BaseSyntheticEvent, useCallback } from "react";

import { Controller, useForm } from "react-hook-form";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputNumber } from "primereact/inputnumber";
import { ALL_OPTION, LABEL } from "../../constants";
import { STATUS_OPTION } from "../constants";

type FilterValues = {
    id: number | null;
    status: string;
};

interface FilterProps {
    onFilter: (values: FilterValues) => void;
    onReset: () => void;
}

const JobsFilter: React.FC<FilterProps> = ({ onFilter, onReset }) => {
    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            jobId: null,
            status: ALL_OPTION,
        },
    });

    const onFilterReset = useCallback(
        (event: BaseSyntheticEvent) => {
            event.preventDefault();
            reset();

            if (typeof onReset === "function") {
                onReset();
            }
        },
        [reset, onReset]
    );

    const submit = (values: any) => {
        const id = values.jobId;

        const params: any = {};

        if (id) {
            params.id = id;
        }

        const status = values.status;
        if (status) {
            params.status = status;
        }

        onFilter(params);
    };

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Fieldset legend={LABEL.common.viewDetail} className="w-fieldset">
                <div className="flex flex-column gap-5">
                    <div className="flex gap-5 col-12 flex-wrap">
                        {/* jobId */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <Controller
                                name="jobId"
                                control={control}
                                render={({
                                    field: {
                                        name,
                                        ref,
                                        value,
                                        onBlur,
                                        onChange,
                                    },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-2"
                                        >
                                            {LABEL.job.id}
                                        </label>
                                        <InputNumber
                                            id={name}
                                            inputRef={ref}
                                            value={value}
                                            onBlur={onBlur}
                                            onValueChange={(e: any) =>
                                                onChange(e)
                                            }
                                            useGrouping={false}
                                            min={0}
                                            className={"w-full"}
                                            inputStyle={{ width: '100%' }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        {/* jobId */}

                        {/* status */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <Controller
                                name="status"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-2"
                                        >
                                            {LABEL.common.status}
                                        </label>
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={[
                                                ALL_OPTION,
                                                ...STATUS_OPTION,
                                            ]}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className="w-full"
                                        />
                                    </>
                                )}
                            />
                        </div>

                    </div>

                    <div className="flex justify-content-end gap-3">
                        <Button
                            severity="secondary"
                            type="button"
                            icon="pi pi-refresh"
                            label={LABEL.common.reset}
                            onClick={(e) => onFilterReset(e)}
                        />
                        <Button
                            label={LABEL.common.search}
                            icon="pi pi-search"
                            type="submit"
                        />
                    </div>
                </div>
            </Fieldset>
        </form>
    );
};

export default JobsFilter;
