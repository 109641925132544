
import { Fragment, ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext, AuthContextType } from "../../contexts/AuthContext";
import { ROLES_ENUM } from "../../pages/constants";
import Unauthorized from "./UnAuthorized";

type AuthorizeProps = {
    children?: ReactNode,
    mode?: "view" | "access",
    hasPermission?: boolean
}
const Authorize = ({ children, mode, hasPermission }: AuthorizeProps) => {
    const { user } = useContext<AuthContextType>(AuthContext);

    const isSupperAdmin = user?.role?.includes(ROLES_ENUM.SUPER_ADMIN)

    let check = false

    if (hasPermission && isSupperAdmin) {
        check = true
    }

    if (!hasPermission && user) {
        check = true
    }

    if (check) {
        return (
            <Fragment>
                {children}
            </Fragment>
        )
    }
    else if (mode === "access") {
        if (!user) {
            return (
                <Navigate to="/auth/login" replace />
            )
        }
        else {
            return (
                <Unauthorized />
            )
        }
    }
    else {
        return null
    }
}

export default Authorize