import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";

import { format } from "date-fns";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Divider } from "primereact/divider";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { Tag } from "primereact/tag";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useApp } from "../../../hooks/useApp";
import { getQADetails, updateQA } from "../../../services/qaService";
import { QACommentType, QADetailsType } from "../../../types/QATypes";
import { LABEL } from "../../constants";
import { DATE, PATH } from "../constants";

const QADetails: React.FC<any> = () => {
    const { id } = useParams();
    const app = useApp()

    const { handleSubmit, control, setValue, reset, watch } = useForm({
        defaultValues: {
            qnaId: '',
            typeCd: '',
            pwd: '',
            subject: '',
            conts: '',
            status: '',
            hidden: false,
            delete: '',
            regId: '',
            regDTime: '',
            updId: '',
            updTime: '',
            comment: '',
        }
    });

    const watchComment = watch("comment");

    const [data, setData] = useState<QADetailsType>({} as QADetailsType);

    const shouldDisableSubmit = useCallback(() => {
        return watchComment ? watchComment.trim() : null;
    }, [watchComment]);

    const submit = async (values: any) => {
        const comment = values.comment || "";

        if (!comment.trim()) return;

        const params: any = {
            qnaId: id,
            comment: values.comment.trim(),
        };

        try {
            await updateQA(params).then((res) => {
                const status = res.status;
                if (status === 200) {
                    getData()
                    app.showToast({
                        type: "success",
                        message: res.data.message
                    })
                    reset({
                        comment: "",
                    });
                }
            });
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            app.showToast({
                type: "error",
                message: message
            })
        }
    };

    const createStatus = useMemo(() => {
        const status = data.status || "";
        if (!status) return null;

        let type: "warning" | "success" = "warning";
        let _status = "문의완료";
        if (status === "REPLIED") {
            type = "success";
            _status = "답변완료";
        }

        return <Tag severity={type} value={_status} />;
    }, [data]);

    const createAnswers = useMemo(() => {
        const comments: QACommentType[] = data.comments || [];

        if (!comments) return null;

        return (
            <div className="flex gap-5 mb-3">
                <Panel header={LABEL.qna.answer} className="w-full">
                    {comments.map((each: QACommentType, index: number) => {
                        return (
                            <div key={each.commentId} className="mb-1">
                                {each.conts}
                                {index < comments.length - 1 && <Divider />}
                            </div>
                        );
                    })}
                </Panel>
            </div>
        );
    }, [data]);

    const getData = useCallback(() => {
        if (id) {
            getQADetails(id)
                .then((res) => {
                    const status = res.status;
                    if (status === 200) {
                        setData(res.data);
                    }
                })
                .catch((error: any) => {
                    const message = error?.response?.data?.message || error?.message;
                    app.showToast({
                        type: "error",
                        message: message
                    })
                })
        }
    }, [id]);


    useEffect(() => {
        getData()
    }, [id]);

    // initial values
    useEffect(() => {
        if (Object.keys(data).length) {
            setValue("qnaId", data.qnaId);
            setValue("subject", data.subject);
            setValue("regId", data.regId);

            const date = data.regDTime;
            if (date) {
                const _date = new Date(
                    date.replace(
                        /^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/,
                        "$4:$5:$6 $2/$3/$1"
                    )
                );
                setValue("regDTime", format(_date, DATE.default));
            }

            const hidden = data.hidden;
            setValue("hidden", hidden === "Y");
        }
    }, [data]);

    return (
        <>
            <Fieldset legend={LABEL.qna.detail} className="w-fieldset">
                <div className="flex justify-content-end mb-3 gap-2">
                    <Link to={PATH.index}>
                        <Button
                            label={LABEL.common.back}
                            icon="pi pi-arrow-left"
                            type="button"
                            severity="secondary"
                        />
                    </Link>
                </div>
                <form onSubmit={handleSubmit(submit)} className="flex flex-column gap-3 w-full">
                    <div className="flex flex-wrap w-full">
                        {/* qnaId */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <label
                                htmlFor="qnaId"
                                className="min-w-label-1"
                            >
                                {LABEL.qna.id}
                            </label>
                            <Controller
                                name="qnaId"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* qnaId */}

                        {/* subject */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <label
                                htmlFor="subject"
                                className="min-w-label-1"
                            >
                                {LABEL.qna.subject}
                            </label>
                            <Controller
                                name="subject"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* subject */}

                        {/* regId */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <label
                                htmlFor="regId"
                                className="min-w-label-1"
                            >
                                {LABEL.qna.regId}
                            </label>
                            <Controller
                                name="regId"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* regId */}

                        {/* regDTime */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <label
                                htmlFor="regDTime"
                                className="min-w-label-1"
                            >
                                {LABEL.qna.regDTime}
                            </label>
                            <Controller
                                name="regDTime"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* regDTime */}
                    </div>

                    <div className="flex flex-wrap w-full">
                        {/* status */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-6 sm:col-6">
                            <label
                                htmlFor="status"
                                className="min-w-label-1"
                            >
                                {LABEL.qna.status}
                            </label>
                            {createStatus}
                        </div>
                        {/* status */}

                        {/* hidden */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-6 sm:col-6">
                            <Controller
                                name="hidden"
                                control={control}
                                render={({
                                    field: { name, value, ref, onChange },
                                }) => (
                                    <>
                                        <Checkbox
                                            inputId={name}
                                            checked={value}
                                            inputRef={ref}
                                            onChange={(e) =>
                                                onChange(e.checked)
                                            }
                                            disabled
                                        />
                                        <label
                                            htmlFor="hidden"
                                            style={{
                                                width: "80px",
                                            }}
                                        >
                                            {LABEL.qna.hidden}
                                        </label>
                                    </>
                                )}
                            />
                        </div>
                        {/* hidden */}
                    </div>

                    <Fieldset legend="Q&A 답변설정" className="mt-4 w-fieldset">
                        <div className="flex gap-5 mb-3">
                            <div className="flex align-items-center gap-2 text-2xl">
                                <strong>문의:</strong>
                                <div>{data.conts}</div>
                            </div>
                        </div>

                        {createAnswers}

                        <div className="flex gap-5 mb-4">
                            {/* comment */}
                            <div className="flex align-items-center gap-2 w-full">
                                <Controller
                                    name="comment"
                                    control={control}
                                    render={({
                                        field: {
                                            name,
                                            value,
                                            onBlur,
                                            onChange,
                                        },
                                    }) => (
                                        <InputTextarea
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            className={"w-full"}
                                            rows={4}
                                            maxLength={400}
                                        />
                                    )}
                                />
                            </div>
                            {/* comment */}
                        </div>

                        <div className="flex justify-content-between align-items-start lg:flex-row md:flex-column sm:flex-column gap-3">
                            <div className="text-500">
                                <strong>주의: </strong>"Q&A 답변설정"에서
                                언제든지 답변 편집 가능
                            </div>
                            <div className="flex align-items-center gap-2">
                                <Link to={PATH.index}>
                                    <Button
                                        label={LABEL.common.cancel}
                                        type="button"
                                        severity="secondary"
                                    />
                                </Link>
                                <Button label={LABEL.common.save} type="submit" disabled={!shouldDisableSubmit()} />
                            </div>
                        </div>
                    </Fieldset>
                </form>
            </Fieldset>
        </>
    );
};

export default QADetails;
