import axiosInstance from "./axiosConfig";
import { SubmitJob } from "../types/JobTypes";

export const getJobsList = (query: string) => {
    return axiosInstance.get(
        `/api/admin/recruitments${query ?? ""}`
    );
};

export const getJobDetails = (id: string) => {
    return axiosInstance.get(`/api/admin/recruitment/${id}`);
};

export const updateJobStatus = (data: SubmitJob) => {
    return axiosInstance.put(`/api/admin/recruitment`, data);
};

export const addNewJob = (data: SubmitJob) => {
    return axiosInstance.post(`/api/admin/recruitment`, data);
};

export const searchJobById = (query: string) => {
    return axiosInstance.get(
        `/api/admin/recruitments/search${query ?? ""}`
    );
};
