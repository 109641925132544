import { useState } from "react";
import Cookies from "universal-cookie";
import { LoggedInUser } from "../types/LoggedInUser";

export const useUser = () => {
    const cookies = new Cookies(null);

    const [user, setUser] = useState<LoggedInUser | null>(()=>{
        return cookies.get("user")
    });

    const addUser = (user: LoggedInUser) => {
        setUser(user);
        cookies.set("user", user, {
            path: "/",
            maxAge: 60 * 60 * 24,
            secure: true
        });

    };

    const removeUser = () => {
        setUser(null);
        cookies.remove("user", { path: "/" });
    };
    return { user, setUser, addUser, removeUser };
}