
export default class StringUtils {
    static isEmptyValue = (value?: any) => {
        if (value === 0 || value === false) {
            return false
        }
        else {
            return (!value || value?.length === 0)
        }
    }

    static clearParams = (params: {}) => {
        let newParams = JSON.parse(JSON.stringify(params))
        if (Object.keys(newParams)?.length > 0) {
            Object.keys(newParams)?.map(key => {
                if (StringUtils.isEmptyValue(newParams[key as keyof typeof newParams])) {
                    delete newParams[key]
                }
            })
        }
        return newParams
    };

    static convertObjectToFormData = (values: {}) => {
        let newValues = JSON.parse(JSON.stringify(values))
        let formData = new FormData();

        Object.keys(newValues).forEach((key) => {
            if (!StringUtils.isEmptyValue(newValues[key as keyof typeof newValues])) {
                if (Array.isArray(newValues[key])) {
                    if (newValues[key].length > 0) {
                        newValues[key].forEach((item: any) => {
                            formData.append(key, item);
                        });
                    }
                } else {
                    formData.append(key, newValues[key]);
                }
            }
        });
        return formData;
    };

    static convertObjectToQueryString = (values: any) => {
        const arr: any[] = []
        const newValues = JSON.parse(JSON.stringify(values))
        let queryStr = ''
        if (Object.keys(newValues).length > 0) {
            Object.keys(newValues)?.forEach((key) => {
                if (!StringUtils.isEmptyValue(newValues[key as keyof typeof newValues])) {
                    if (Array.isArray(newValues[key])) {
                        let values = newValues[key];
                        values?.forEach((value: any) => {
                            arr.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
                        });
                    } else {
                        arr.push(
                            encodeURIComponent(key) + "=" + encodeURIComponent(newValues[key])
                        );
                    }
                }
            })
            queryStr = `?${arr.join("&")}`
        }
        return queryStr;
    };

    static convertPhoneNumber = (value: string) => {
        if (!value) {
            return {
                format: '',
                object: { first: {}, middle: '', end: '' }
            };
        }

        const [areaCode, middle, end] = value.split('-');

        return {
            format: `${areaCode}-${middle}-${end}`,
            object: {
                first: { code: areaCode, name: areaCode },
                middle,
                end,
            }
        };
    }

    static createLanguageValues = (values: any, languageCode: string) => {
        if (!values && !languageCode) {
            return {}
        }
        return {
            [`status${languageCode}`]: values?.[`status${languageCode}`]?.code,
            [`hashTags${languageCode}`]: values?.[`hashTags${languageCode}`]?.filter((each: string) => each).join(","),
            [`description${languageCode}`]: values?.[`description${languageCode}`],
            [`title${languageCode}`]: values?.[`title${languageCode}`],
            [`thumbnail${languageCode}`]: values?.[`thumbnail${languageCode}`],
        };
    };
}