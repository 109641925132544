import { isAfter } from "date-fns";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import React, { BaseSyntheticEvent, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { useApp } from "../../../hooks/useApp";
import { ALL_OPTION, LABEL, MESSAGE, FORMAT_MOBILE_PHONE, FORMAT_TELEPHONE } from "../../constants";
import { FORM_TYPE_OPTION } from "../constants";

type FilterValues = {
    language: string,
    contactForm: string,
    company: string,
    email: string,
    fullName: string,
    telephone: number,
    mobiphone: number,
    createDateStart: string,
    createDateEnd: string
};

interface ContactProps {
    onFilter: (values: FilterValues) => void;
    onReset: () => void;
}

const ContactFilter: React.FC<ContactProps> = ({ onFilter, onReset }) => {
    const app = useApp()

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            language: null,
            contactForm: ALL_OPTION,
            company: '',
            email: '',
            companyAddress: null,
            fullName: '',
            telephone: '',
            mobiphone: '',
            passCode: null,
            createDateStart: null,
            createDateEnd: null
        },
    });

    const onFilterReset = useCallback(
        (event: BaseSyntheticEvent) => {
            event.preventDefault();
            reset();

            if (typeof onReset === "function") {
                onReset();
            }
        },
        [reset, onReset]
    );

    const submit = (values: any) => {
        if (typeof onFilter !== "function") return;

        // start date < end date validation
        const startDate = values.createDateStart;
        const endDate = values.createDateEnd;

        const tel = values.telephone;
        const mobil = values?.mobiphone.replaceAll("-", "");

        if (startDate && endDate) {
            const invalid = isAfter(startDate, endDate);

            if (invalid) {
                app.showToast({
                    type: "error",
                    message: MESSAGE.validate.inValidStartEndDate,
                })
                return;
            }
        }

        if (tel && !FORMAT_TELEPHONE.test(tel)) {
            app.showToast({
                type: "error",
                message: MESSAGE.validate.telephone,
            })
            return;
        }

        if (mobil && !FORMAT_MOBILE_PHONE.test(mobil)) {
            app.showToast({
                type: "error",
                message: MESSAGE.validate.mobilePhone,
            })
            return;
        }
        onFilter(values);
    };

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <Fieldset legend={LABEL.common.viewDetail} className="w-fieldset">
                    <div className="flex flex-wrap gap-2 w-full">
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <Controller
                                name="fullName"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.contact.fullName}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <Controller
                                name="company"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.contact.company}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <Controller
                                name="contactForm"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.contact.formType}
                                        </label>
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={[
                                                ALL_OPTION,
                                                ...FORM_TYPE_OPTION,
                                            ]}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.common.email}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <Controller
                                name="telephone"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.contact.telephone}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <Controller
                                name="mobiphone"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.contact.mobilePhone}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <label className="min-w-label-1">
                                {LABEL.common.createDate}
                            </label>
                            <Controller
                                name="createDateStart"
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                }) => (
                                    <>
                                        <Calendar
                                            inputId={name}
                                            value={value}
                                            dateFormat="yy-mm-dd"
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                        <div className="flex align-items-center gap-2 lg:col-3 md:col-5 sm:col-12">
                            <label className="min-w-label-1 flex align-items-center justify-content-center">
                                ~
                            </label>
                            <Controller
                                name="createDateEnd"
                                control={control}
                                render={({
                                    field: { name, value, onChange },
                                }) => (
                                    <>
                                        <Calendar
                                            inputId={name}
                                            value={value}
                                            dateFormat="yy-mm-dd"
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>

                    </div>

                    <div className="flex justify-content-end gap-3">
                        <Button
                            severity="secondary"
                            type="button"
                            icon="pi pi-refresh"
                            label={LABEL.common.reset}
                            onClick={(e) => onFilterReset(e)}
                        />
                        <Button
                            label={LABEL.common.search}
                            icon="pi pi-search"
                            type="submit"
                        />
                    </div>
                </Fieldset>
            </form>
        </>
    );
};

export default ContactFilter;
