import React, { useCallback } from "react";

import { UserType } from "../../../types/UserTypes";
import { PATH, ROLES_OPTION } from "../constants";

import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";

import { format } from "date-fns/esm";
import DetailBtn from "../../../components/common/DetailBtn";
import { DATE_FORMAT, LABEL } from "../../constants";
import ListSwitch from "./Switch";

interface UserProps {
    data: UserType[];
    pagingSpec: any;
    onUpdate: () => void;
}

const UserList: React.FC<UserProps> = ({ data, pagingSpec, onUpdate }) => {
    const createRole = useCallback((role: string) => {
        if (!role) return null;
        const _role = role.split(", ");

        if (!_role.length) return null;

        return _role.map((each: string, index: number) => {
            const mapped = ROLES_OPTION.filter((eachRole: any) => eachRole.code === each)[0];
            const comma = index !== _role.length - 1 ? ", " : ''
            return `${mapped?.name}${comma}`
        });
    }, []);

    const renderStatus = (data: UserType) => {
        const _type = data?.accountLocked ? 'warning' : 'success' as any
        const _status = data?.accountLocked ? LABEL.account.unActive : LABEL.account.active
        return (
            <div className="flex align-items-center gap-2 justify-content-center">
                <Tag severity={_type} value={_status} />
                <ListSwitch data={data} onUpdate={onUpdate} />
            </div>
        );
    }

    const renderDetailBtn = (data: UserType) => {
        return <DetailBtn path={PATH.details} param={data?.email} />
    }

    return (
        <DataTable value={data}>
            <Column
                header="#"
                body={(data: UserType, props: ColumnBodyOptions) => {
                    const index = props.rowIndex;
                    const page = pagingSpec.page;
                    const size = pagingSpec.size;

                    return page * size + index + 1;
                }}
            />
            <Column field={"email"} header={LABEL.common.email} />
            <Column field={"fullName"} header={LABEL.common.fullName} />
            <Column
                header={LABEL.user.role}
                body={(data: UserType) => {
                    return createRole(data?.role);
                }}
            />
            <Column
                header={LABEL.common.createDate}
                align={"center"}
                body={(data: UserType) => {
                    const date = data?.regDTime ? new Date(data?.regDTime) : null
                    return date ? format(date, DATE_FORMAT) : '';
                }}
            />

            <Column
                header={LABEL.common.updateDate}
                align={"center"}
                body={(data: UserType) => {
                    const date = data?.updTime ? new Date(data?.updTime) : null
                    return date ? format(date, DATE_FORMAT) : '';
                }}
            />
            <Column
                header={LABEL.common.status}
                align={"center"}
                body={(data: UserType) => { return renderStatus(data) }}
            />

            <Column
                header={LABEL.common.addDetail}
                align={"center"}
                body={(data: UserType) => { return renderDetailBtn(data) }}
            />
        </DataTable>
    );
};

export default UserList;
