import React, { useCallback, useEffect, useState } from "react";

import { filterUser } from "../../../services/userService";
import { UserType } from "../../../types/UserTypes";
import { Fieldset } from "primereact/fieldset";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import Paging from "../../../components/common/Paging";
import { useApp } from "../../../hooks/useApp";
import StringUtils from "../../../utils/stringUtils";
import { LABEL } from "../../constants";
import { PATH } from "../constants";
import UserFilter from "./Filter";
import UserList from "./List";

const UserManagement: React.FC<any> = () => {
    const app = useApp();

    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [total, setTotal] = useState<number>(0);
    const [data, setData] = useState<UserType[]>([]);
    const [pagingSpec, setPagingSpec] = useState<any>({});
    const [query, setQuery] = useState<any>({});

    const getData = useCallback((response: any) => {
        const resData = response.data;
        setData(resData.userDtos);
        setTotal(resData.paginationSpec.totalElements);
        setPagingSpec(resData.paginationSpec);
    }, []);

    const getList = useCallback(async () => {
        const params: any = {
            ...query,
            page,
            size,
        };

        const _newQuery = StringUtils.convertObjectToQueryString(params);
        try {
            await filterUser(_newQuery).then((res) => {
                const status = res.status;

                if (status === 200) {
                    getData(res);
                }
            });
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            app.showToast({
                type: 'error',
                message: message
            })
        }
    }, [page, size, getData]);

    const onFilter = useCallback(
        (values: any) => {
            setPage(0);

            const params: any = {
                page: 0,
                size,
            };

            const fullName = values.fullName;
            if (fullName?.trim()) {
                params.fullName = fullName;
            }

            const userID = values.userID;
            if (userID?.trim()) {
                params.email = userID;
            }

            const status = values.status;
            if (status && Object.keys(status).length) {
                const code = status.code;

                if (code !== "all") {
                    params.accountLocked = code;
                }
            }

            const role = values.role;
            if (role && Object.keys(role).length) {
                const code = role.code;

                if (code && code !== "all") {
                    params.role = code;
                }
            }

            setQuery(params);
        },
        [size]
    );

    const onReset = useCallback(() => {
        setPage(0);
        setSize(10);
        setQuery({});
        getList();
    }, [query]);

    const filterData = useCallback(async () => {
        const _query = StringUtils.convertObjectToQueryString(query);

        try {
            await filterUser(_query).then((res) => {
                const status = res.status;
                if (status === 200) {
                    getData(res);
                }
            });
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            app.showToast({
                type: 'error',
                message: message
            })
        }
    }, [query]);

    useEffect(() => {
        getList();
    }, [page, size, getList]);

    useEffect(() => {
        if (Object.keys(query).length) {
            filterData();
        }
    }, [query]);

    return (
        <>
            <p className="text-lg font-bold">사용자관리</p>
            <UserFilter onFilter={onFilter} onReset={onReset} />
            <Fieldset legend={LABEL.user.list} className="mt-3 w-fieldset">
                <div className="flex justify-content-end align-items-center mb-3">
                    <Link to={PATH.new}>
                        <Button label={LABEL.common.addNew} icon="pi pi-plus" />
                    </Link>
                </div>
                <UserList
                    data={data}
                    pagingSpec={pagingSpec}
                    onUpdate={getList}
                />
                <Paging
                    total={total}
                    currentPage={page}
                    rows={size}
                    onChangePage={(e: number) => setPage(e)}
                    onChangeSize={(e: number) => setSize(e)}
                />
            </Fieldset>
        </>
    );
};

export default UserManagement;
