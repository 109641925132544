import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthContext, AuthContextType } from '../../contexts/AuthContext';
import { ROLES_ENUM } from "../constants";

const Home: React.FC<any> = () => {
    const { user } = useContext<AuthContextType>(AuthContext);

    const isSupperAdmin = user?.role?.some((role: string) => role === ROLES_ENUM.SUPER_ADMIN)
    const navigate = useNavigate()

    useEffect(() => {
        if (user) {
            if (user?.firstLogin) {
                navigate("/change-password")
            }
            else if (isSupperAdmin) {
                navigate("/user")
            }
            else {
                navigate("/job")
            }
        }
    }, [])

    return null
}
export default Home;