import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import images from '../../../assets/images/images';
import Footer from '../../../components/common/Footer';
import { forgotPassword } from '../../../services/userService';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { MESSAGE } from '../../constants';

type FormValues = {
    email: string
}

function ForgotPassword() {
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>('');
    const [displayMsg, setDisplayMsg] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const { control, handleSubmit, formState } = useForm<FormValues>({
        defaultValues: {
            email: ''
        }
    });

    const { errors } = formState;

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        forgotPassword(data.email)
            .then(response => {
                setIsLoading(false);
                setMessage(response.data?.message);
                setDisplayMsg(true);
                setIsSuccess(true);
            })
            .catch(error => {
                setIsLoading(false);
                if (error.response?.status === 500) {
                    setMessage(MESSAGE.error.system);
                } else {
                    error.response?.data ?
                        setMessage(error.response.data?.message)
                        : setMessage(MESSAGE.error.system);
                }
                setDisplayMsg(true);
                setIsSuccess(false);
            });


    };

    const handleCloseErrorPopup = () => {
        setDisplayMsg(false);
        setMessage('');
    }
    return (
        <>
            <Dialog visible={displayMsg} header="시스템 메시지" style={{ width: '30vw' }} onHide={() => handleCloseErrorPopup()}>
                <div className=" flex flex-row  m-0">
                    {isSuccess ?
                        <CheckBoxOutlinedIcon className="mr-4" sx={{ color: 'green', fontSize: 60 }}></CheckBoxOutlinedIcon> :
                        <WarningAmberIcon className="mr-4" sx={{ color: 'red', fontSize: 60 }} ></WarningAmberIcon>
                    }
                    <div className='mt-3'> {message}</div>
                </div>
            </Dialog>
            <div className="min-h-screen flex flex-column justify-content-between">
                <div className="h-full w-full text-center px-6 pt-6 flex flex-column">
                    <img src={images.logo} className="h-4rem mt-8" alt="EVSIS" />
                    <div className="flex flex-column align-items-center gap-4 mt-8">
                        <div className="mb-3">
                            <h2>비밀번호 찾기</h2>
                            <p>비밀번호 찾기위해서 이메일 (Login ID)을 입력해주시기 바랍니다.</p>
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="formgroup-inline">
                                <div className="field">
                                    <span className="p-input-icon-left w-full md:w-30rem">
                                        <i className="pi pi-envelope"></i>
                                        <Controller name="email" control={control}
                                            rules={{
                                                required: '이메일이 필요합니다. 확인하시고 재시도 부탁드립니다.',
                                                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: '이메일 형식이 잘못되었습니다. 확인하시고 재시도 부탁드립니다.' }
                                            }}
                                            render={({ field, fieldState }) => (
                                                <InputText type="text" id={field.name} {...field} autoFocus placeholder="ID/Email"
                                                    className={classNames({ 'p-invalid': fieldState.error }, "w-full md:w-30rem appearance-none outline-none")} />
                                            )}
                                        />
                                    </span>
                                </div>
                                <button className="text-center block surface-400 text-900 font-semibold surface-border cursor-pointer border-round py-2 px-3">전송</button>
                            </div>
                            {errors.email &&
                                <div className="grid w-30rem">
                                    <small className="col p-error text-left">{errors.email.message}</small>
                                </div>
                            }
                        </form>
                        {isLoading ?
                            <ProgressSpinner style={{ width: '40px', height: '40px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            : null
                        }
                        <div className="flex gap-3 ">
                            <button className="text-center block surface-400 text-900 font-semibold surface-border cursor-pointer border-round py-2 px-3"
                                onClick={() => navigate("/auth/login")}>로그인 페이지 뒤로</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ForgotPassword;
