import { createContext, useMemo } from "react";
import { LoggedInUser } from "../types/LoggedInUser";
import { useUser } from "../hooks/useUser";

export interface AuthContextType {
    user: LoggedInUser | null,
    setUser: (user: LoggedInUser | null) => void,
    addUser: (user: LoggedInUser) => void,
    removeUser: () => void
}

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = (props: any) => {
    const { user, setUser, addUser, removeUser } = useUser();

    const contextValue = useMemo(() => {
        return { user, setUser, addUser, removeUser };
      }, [user, setUser, addUser, removeUser]);

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
}