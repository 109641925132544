import { useContext, useState } from "react";
import { ToastContext } from "../contexts/ToastContext";
import { ToastContextTypes } from "../types/ToastTypes";

export const useApp = () => {
    const { showToast } = useContext<ToastContextTypes>(ToastContext);

    const [app] = useState({
        showToast: showToast
    })

    return app;
}