import { ChangePasswordForm } from "../types/ChangePasswordForm";
import { NewPasswordForm } from "../types/NewPasswordForm";
import { UserAdminType } from "../types/UserTypes";
import axiosInstance from "./axiosConfig";

export function forgotPassword(email: string) {
    return axiosInstance.post(`api/public/forgot-password`, null, {
        params: {
            email,
        },
    });
}

export function createNewPassword(
    data: NewPasswordForm,
    passwordToken: string | null
) {
    return axiosInstance.post("api/public/reset-password", data, {
        params: { passwordToken: passwordToken },
    });
}

export function changePassword(data: ChangePasswordForm) {
    return axiosInstance.post("api/admin/change-password", data);
}

export const getUserList = (query: string) => {
    return axiosInstance.get(
        `/api/management/users${query ?? ""}`
    );
};

export const changeUserStatus = (email: string) => {
    return axiosInstance.put(`/api/management/user/delete-user?email=${email}`);
};

export const filterUser = (query: string) => {
    return axiosInstance.get(
        `/api/management/user/filter${query ?? ""}`
    );
};

export const createUser = (data: UserAdminType) => {
    return axiosInstance.post("api/management/user", data);
}

export const updateUser = (data: UserAdminType) => {
    return axiosInstance.put("api/management/user", data);
}

export const getUserDetails = (query: string) => {
    return axiosInstance.get(`/api/management/user${query ?? ""}`);
}

export const resetPassword = (data: any) => {
    return axiosInstance.put("api/management/user/reset-password", data);
}