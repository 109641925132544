import { LABEL } from "../constants";

export const FORM_TYPE_ENUM = {
    contact: "CONTACT_US",
    payment: 'PAYMENT'
}

export const FORM_TYPE_OPTION = [
    { name: LABEL.common.contact, code: FORM_TYPE_ENUM.contact },
    { name: LABEL.common.payment, code: FORM_TYPE_ENUM.payment },
];

export const FORM_TYPE_LABEL = {
    [FORM_TYPE_ENUM.contact]: LABEL.common.contact,
    [FORM_TYPE_ENUM.payment]: LABEL.common.payment
}

export const LANG_TAB = [
    { label: "All", value: "all" },
    { label: "Korean", value: "KR" },
    { label: "English", value: "EN" },
];

export const PATH = {
    index: "/contact",
    details: "/contact/details",
    edit: "/contact/edit",
};

export const DATE = {
    default: "yyyy-MM-dd",
};

export const BUILD_TYPE_ENUM = {
    apartment: "APARTMENT",
    officetel: 'OFFICETEL',
    building: 'BUILDING',
    office: 'OFFICE',
    other: 'OTHERS'
}

export const BUILDING_TYPE_OPTION = [
    { name: LABEL.contact.apartment, code: BUILD_TYPE_ENUM.apartment },
    { name: LABEL.contact.officetel, code: BUILD_TYPE_ENUM.officetel },
    { name: LABEL.contact.building, code: BUILD_TYPE_ENUM.building },
    { name: LABEL.contact.office, code: BUILD_TYPE_ENUM.office },
    { name: LABEL.contact.other, code: BUILD_TYPE_ENUM.other },
];


