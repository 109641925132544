import { CountryType } from "../types/Layout";


 export const Countries: CountryType[] = [
    {
      "label": "Afghanistan",
      "value": "AFGHANISTAN"
    },
    {
      "label": "Albania",
      "value": "ALBANIA"
    },
    {
      "label": "Algeria",
      "value": "ALGERIA"
    },
    {
      "label": "American Samoa",
      "value": "AMERICAN_SAMOA"
    },
    {
      "label": "Andorra",
      "value": "ANDORRA"
    },
    {
      "label": "Angola",
      "value": "ANGOLA"
    },
    {
      "label": "Anguilla",
      "value": "ANGUILLA"
    },
    {
      "label": "Antarctica",
      "value": "ANTARCTICA"
    },
    {
      "label": "Antigua and Barbuda",
      "value": "ANTIGUA_AND_BARBUDA"
    },
    {
      "label": "Argentina",
      "value": "ARGENTINA"
    },
    {
      "label": "Armenia",
      "value": "ARMENIA"
    },
    {
      "label": "Aruba",
      "value": "ARUBA"
    },
    {
      "label": "Australia",
      "value": "AUSTRALIA"
    },
    {
      "label": "Austria",
      "value": "AUSTRIA"
    },
    {
      "label": "Azerbaijan",
      "value": "AZERBAIJAN"
    },
    {
      "label": "Bahamas",
      "value": "BAHAMAS"
    },
    {
      "label": "Bahrain",
      "value": "BAHRAIN"
    },
    {
      "label": "Bangladesh",
      "value": "BANGLADESH"
    },
    {
      "label": "Barbados",
      "value": "BARBADOS"
    },
    {
      "label": "Belarus",
      "value": "BELARUS"
    },
    {
      "label": "Belgium",
      "value": "BELGIUM"
    },
    {
      "label": "Belize",
      "value": "BELIZE"
    },
    {
      "label": "Benin",
      "value": "BENIN"
    },
    {
      "label": "Bermuda",
      "value": "BERMUDA"
    },
    {
      "label": "Bhutan",
      "value": "BHUTAN"
    },
    {
      "label": "Bolivia",
      "value": "BOLIVIA"
    },
    {
      "label": "Bosnia and Herzegovina",
      "value": "BOSNIA_AND_HERZEGOVINA"
    },
    {
      "label": "Botswana",
      "value": "BOTSWANA"
    },
    {
      "label": "Bouvet Island",
      "value": "BOUVET_ISLAND"
    },
    {
      "label": "Brazil",
      "value": "BRAZIL"
    },
    {
      "label": "British Indian Ocean Territory",
      "value": "BRITISH_INDIAN_OCEAN_TERRITORY"
    },
    {
      "label": "Brunei",
      "value": "BRUNEI"
    },
    {
      "label": "Bulgaria",
      "value": "BULGARIA"
    },
    {
      "label": "Burkina Faso",
      "value": "BURKINA_FASO"
    },
    {
      "label": "Burundi",
      "value": "BURUNDI"
    },
    {
      "label": "Cambodia",
      "value": "CAMBODIA"
    },
    {
      "label": "Cameroon",
      "value": "CAMEROON"
    },
    {
      "label": "Canada",
      "value": "CANADA"
    },
    {
      "label": "Cape Verde",
      "value": "CAPE_VERDE"
    },
    {
      "label": "Cayman Islands",
      "value": "CAYMAN_ISLANDS"
    },
    {
      "label": "Central African Republic",
      "value": "CENTRAL_AFRICAN_REPUBLIC"
    },
    {
      "label": "Chad",
      "value": "CHAD"
    },
    {
      "label": "Chile",
      "value": "CHILE"
    },
    {
      "label": "China",
      "value": "CHINA"
    },
    {
      "label": "Christmas Island",
      "value": "CHRISTMAS_ISLAND"
    },
    {
      "label": "Cocos (Keeling) Islands",
      "value": "COCOS_KEELING_ISLANDS"
    },
    {
      "label": "Colombia",
      "value": "COLOMBIA"
    },
    {
      "label": "Comoros",
      "value": "COMOROS"
    },
    {
      "label": "Congo",
      "value": "CONGO"
    },
    {
      "label": "The Democratic Republic of Congo",
      "value": "THE_DEMOCRATIC_REPUBLIC_OF_CONGO"
    },
    {
      "label": "Cook Islands",
      "value": "COOK_ISLANDS"
    },
    {
      "label": "Costa Rica",
      "value": "COSTA_RICA"
    },
    {
      "label": "Ivory Coast",
      "value": "IVORY_COAST"
    },
    {
      "label": "Croatia",
      "value": "CROATIA"
    },
    {
      "label": "Cuba",
      "value": "CUBA"
    },
    {
      "label": "Cyprus",
      "value": "CYPRUS"
    },
    {
      "label": "Czech Republic",
      "value": "CZECH_REPUBLIC"
    },
    {
      "label": "Denmark",
      "value": "DENMARK"
    },
    {
      "label": "Djibouti",
      "value": "DJIBOUTI"
    },
    {
      "label": "Dominica",
      "value": "DOMINICA"
    },
    {
      "label": "Dominican Republic",
      "value": "DOMINICAN_REPUBLIC"
    },
    {
      "label": "East Timor",
      "value": "EAST_TIMOR"
    },
    {
      "label": "Ecuador",
      "value": "ECUADOR"
    },
    {
      "label": "Egypt",
      "value": "EGYPT"
    },
    {
      "label": "England",
      "value": "ENGLAND"
    },
    {
      "label": "El Salvador",
      "value": "EL_SALVADOR"
    },
    {
      "label": "Equatorial Guinea",
      "value": "EQUATORIAL_GUINEA"
    },
    {
      "label": "Eritrea",
      "value": "ERITREA"
    },
    {
      "label": "Estonia",
      "value": "ESTONIA"
    },
    {
      "label": "Ethiopia",
      "value": "ETHIOPIA"
    },
    {
      "label": "Falkland Islands",
      "value": "FALKLAND_ISLANDS"
    },
    {
      "label": "Faroe Islands",
      "value": "FAROE_ISLANDS"
    },
    {
      "label": "Fiji Islands",
      "value": "FIJI_ISLANDS"
    },
    {
      "label": "Finland",
      "value": "FINLAND"
    },
    {
      "label": "France",
      "value": "FRANCE"
    },
    {
      "label": "French Guiana",
      "value": "FRENCH_GUIANA"
    },
    {
      "label": "French Polynesia",
      "value": "FRENCH_POLYNESIA"
    },
    {
      "label": "French Southern territories",
      "value": "FRENCH_SOUTHERN_TERRITORIES"
    },
    {
      "label": "Gabon",
      "value": "GABON"
    },
    {
      "label": "Gambia",
      "value": "GAMBIA"
    },
    {
      "label": "Georgia",
      "value": "GEORGIA"
    },
    {
      "label": "Germany",
      "value": "GERMANY"
    },
    {
      "label": "Ghana",
      "value": "GHANA"
    },
    {
      "label": "Gibraltar",
      "value": "GIBRALTAR"
    },
    {
      "label": "Greece",
      "value": "GREECE"
    },
    {
      "label": "Greenland",
      "value": "GREENLAND"
    },
    {
      "label": "Grenada",
      "value": "GRENADA"
    },
    {
      "label": "Guadeloupe",
      "value": "GUADELOUPE"
    },
    {
      "label": "Guam",
      "value": "GUAM"
    },
    {
      "label": "Guatemala",
      "value": "GUATEMALA"
    },
    {
      "label": "Guernsey",
      "value": "GUERNSEY"
    },
    {
      "label": "Guinea",
      "value": "GUINEA"
    },
    {
      "label": "Guinea-Bissau",
      "value": "GUINEA-BISSAU"
    },
    {
      "label": "Guyana",
      "value": "GUYANA"
    },
    {
      "label": "Haiti",
      "value": "HAITI"
    },
    {
      "label": "Heard Island and McDonald Islands",
      "value": "HEARD_ISLAND_AND_MCDONALD_ISLANDS"
    },
    {
      "label": "Holy See (Vatican City State",
      "value": "HOLY_SEE"
    },
    {
      "label": "Honduras",
      "value": "HONDURAS"
    },
    {
      "label": "Hong Kong",
      "value": "HONG_KONG"
    },
    {
      "label": "Hungary",
      "value": "HUNGARY"
    },
    {
      "label": "Iceland",
      "value": "ICELAND"
    },
    {
      "label": "India",
      "value": "INDIA"
    },
    {
      "label": "Indonesia",
      "value": "INDONESIA"
    },
    {
      "label": "Iran",
      "value": "IRAN"
    },
    {
      "label": "Iraq",
      "value": "IRAQ"
    },
    {
      "label": "Ireland",
      "value": "IRELAND"
    },
    {
      "label": "Israel",
      "value": "ISRAEL"
    },
    {
      "label": "Isle of Man",
      "value": "ISLE_OF_MAN"
    },
    {
      "label": "Italy",
      "value": "ITALY"
    },
    {
      "label": "Jamaica",
      "value": "JAMAICA"
    },
    {
      "label": "Japan",
      "value": "JAPAN"
    },
    {
      "label": "Jersey",
      "value": "JERSEY"
    },
    {
      "label": "Jordan",
      "value": "JORDAN"
    },
    {
      "label": "Kazakhstan",
      "value": "KAZAKHSTAN"
    },
    {
      "label": "Kenya",
      "value": "KENYA"
    },
    {
      "label": "Kiribati",
      "value": "KIRIBATI"
    },
    {
      "label": "Kuwait",
      "value": "KUWAIT"
    },
    {
      "label": "Kyrgyzstan",
      "value": "KYRGYZSTAN"
    },
    {
      "label": "Laos",
      "value": "LAOS"
    },
    {
      "label": "Latvia",
      "value": "LATVIA"
    },
    {
      "label": "Lebanon",
      "value": "LEBANON"
    },
    {
      "label": "Lesotho",
      "value": "LESOTHO"
    },
    {
      "label": "Liberia",
      "value": "LIBERIA"
    },
    {
      "label": "Libyan Arab Jamahiriya",
      "value": "LIBYAN_ARAB_JAMAHIRIYA"
    },
    {
      "label": "Liechtenstein",
      "value": "LIECHTENSTEIN"
    },
    {
      "label": "Lithuania",
      "value": "LITHUANIA"
    },
    {
      "label": "Luxembourg",
      "value": "LUXEMBOURG"
    },
    {
      "label": "Macao",
      "value": "MACAO"
    },
    {
      "label": "North Macedonia",
      "value": "NORTH_MACEDONIA"
    },
    {
      "label": "Madagascar",
      "value": "MADAGASCAR"
    },
    {
      "label": "Malawi",
      "value": "MALAWI"
    },
    {
      "label": "Malaysia",
      "value": "MALAYSIA"
    },
    {
      "label": "Maldives",
      "value": "MALDIVES"
    },
    {
      "label": "Mali",
      "value": "MALI"
    },
    {
      "label": "Malta",
      "value": "MALTA"
    },
    {
      "label": "Marshall Islands",
      "value": "MARSHALL_ISLANDS"
    },
    {
      "label": "Martinique",
      "value": "MARTINIQUE"
    },
    {
      "label": "Mauritania",
      "value": "MAURITANIA"
    },
    {
      "label": "Mauritius",
      "value": "MAURITIUS"
    },
    {
      "label": "Mayotte",
      "value": "MAYOTTE"
    },
    {
      "label": "Mexico",
      "value": "MEXICO"
    },
    {
      "label": "Micronesia, Federated States of",
      "value": "MICRONESIA_FEDERATED_STATES_OF"
    },
    {
      "label": "Moldova",
      "value": "MOLDOVA"
    },
    {
      "label": "Monaco",
      "value": "MONACO"
    },
    {
      "label": "Mongolia",
      "value": "MONGOLIA"
    },
    {
      "label": "Montserrat",
      "value": "MONTSERRAT"
    },
    {
      "label": "Montenegro",
      "value": "MONTENEGRO"
    },
    {
      "label": "Morocco",
      "value": "MOROCCO"
    },
    {
      "label": "Mozambique",
      "value": "MOZAMBIQUE"
    },
    {
      "label": "Myanmar",
      "value": "MYANMAR"
    },
    {
      "label": "Namibia",
      "value": "NAMIBIA"
    },
    {
      "label": "Nauru",
      "value": "NAURU"
    },
    {
      "label": "Nepal",
      "value": "NEPAL"
    },
    {
      "label": "Netherlands",
      "value": "NETHERLANDS"
    },
    {
      "label": "Netherlands Antilles",
      "value": "NETHERLANDS_ANTILLES"
    },
    {
      "label": "New Caledonia",
      "value": "NEW_CALEDONIA"
    },
    {
      "label": "New Zealand",
      "value": "NEW_ZEALAND"
    },
    {
      "label": "Nicaragua",
      "value": "NICARAGUA"
    },
    {
      "label": "Niger",
      "value": "NIGER"
    },
    {
      "label": "Nigeria",
      "value": "NIGERIA"
    },
    {
      "label": "Niue",
      "value": "NIUE"
    },
    {
      "label": "Norfolk Island",
      "value": "NORFOLK_ISLAND"
    },
    {
      "label": "North Korea",
      "value": "NORTH_KOREA"
    },
    {
      "label": "Northern Ireland",
      "value": "NORTHERN_IRELAND"
    },
    {
      "label": "Northern Mariana Islands",
      "value": "NORTHERN_MARIANA_ISLANDS"
    },
    {
      "label": "Norway",
      "value": "NORWAY"
    },
    {
      "label": "Oman",
      "value": "OMAN"
    },
    {
      "label": "Pakistan",
      "value": "PAKISTAN"
    },
    {
      "label": "Palau",
      "value": "PALAU"
    },
    {
      "label": "Palestine",
      "value": "PALESTINE"
    },
    {
      "label": "Panama",
      "value": "PANAMA"
    },
    {
      "label": "Papua New Guinea",
      "value": "PAPUA_NEW_GUINEA"
    },
    {
      "label": "Paraguay",
      "value": "PARAGUAY"
    },
    {
      "label": "Peru",
      "value": "PERU"
    },
    {
      "label": "Philippines",
      "value": "PHILIPPINES"
    },
    {
      "label": "Pitcairn",
      "value": "PITCAIRN"
    },
    {
      "label": "Poland",
      "value": "POLAND"
    },
    {
      "label": "Portugal",
      "value": "PORTUGAL"
    },
    {
      "label": "Puerto Rico",
      "value": "PUERTO_RICO"
    },
    {
      "label": "Qatar",
      "value": "QATAR"
    },
    {
      "label": "Reunion",
      "value": "REUNION"
    },
    {
      "label": "Romania",
      "value": "ROMANIA"
    },
    {
      "label": "Russian Federation",
      "value": "RUSSIAN_FEDERATION"
    },
    {
      "label": "Rwanda",
      "value": "RWANDA"
    },
    {
      "label": "Saint Helena",
      "value": "SAINT_HELENA"
    },
    {
      "label": "Saint Kitts and Nevis",
      "value": "SAINT_KITTS_AND_NEVIS"
    },
    {
      "label": "Saint Lucia",
      "value": "SAINT_LUCIA"
    },
    {
      "label": "Saint Pierre and Miquelon",
      "value": "SAINT_PIERRE_AND_MIQUELON"
    },
    {
      "label": "Saint Vincent and the Grenadines",
      "value": "SAINT_VINCENT_AND_THE_GRENADINES"
    },
    {
      "label": "Samoa",
      "value": "SAMOA"
    },
    {
      "label": "San Marino",
      "value": "SAN_MARINO"
    },
    {
      "label": "Sao Tome and Principe",
      "value": "SAO_TOME_AND_PRINCIPE"
    },
    {
      "label": "Saudi Arabia",
      "value": "SAUDI_ARABIA"
    },
    {
      "label": "Scotland",
      "value": "SCOTLAND"
    },
    {
      "label": "Senegal",
      "value": "SENEGAL"
    },
    {
      "label": "Serbia",
      "value": "SERBIA"
    },
    {
      "label": "Seychelles",
      "value": "SEYCHELLES"
    },
    {
      "label": "Sierra Leone",
      "value": "SIERRA_LEONE"
    },
    {
      "label": "Singapore",
      "value": "SINGAPORE"
    },
    {
      "label": "Slovakia",
      "value": "SLOVAKIA"
    },
    {
      "label": "Slovenia",
      "value": "SLOVENIA"
    },
    {
      "label": "Solomon Islands",
      "value": "SOLOMON_ISLANDS"
    },
    {
      "label": "Somalia",
      "value": "SOMALIA"
    },
    {
      "label": "South Africa",
      "value": "SOUTH_AFRICA"
    },
    {
      "label": "South Georgia and the South Sandwich Islands",
      "value": "SOUTH_GEORGIA_AND_THE_SOUTH_SANDWICH_ISLANDS"
    },
    {
      "label": "South Korea",
      "value": "SOUTH_KOREA"
    },
    {
      "label": "South Sudan",
      "value": "SOUTH_SUDAN"
    },
    {
      "label": "Spain",
      "value": "SPAIN"
    },
    {
      "label": "Sri Lanka",
      "value": "SRI_LANKA"
    },
    {
      "label": "Sudan",
      "value": "SUDAN"
    },
    {
      "label": "Suriname",
      "value": "SURINAME"
    },
    {
      "label": "Svalbard and Jan Mayen",
      "value": "SVALBARD_AND_JAN_MAYEN"
    },
    {
      "label": "Swaziland",
      "value": "SWAZILAND"
    },
    {
      "label": "Sweden",
      "value": "SWEDEN"
    },
    {
      "label": "Switzerland",
      "value": "SWITZERLAND"
    },
    {
      "label": "Syria",
      "value": "SYRIA"
    },
    {
      "label": "Tajikistan",
      "value": "TAJIKISTAN"
    },
    {
      "label": "Tanzania",
      "value": "TANZANIA"
    },
    {
      "label": "Thailand",
      "value": "THAILAND"
    },
    {
      "label": "Timor-Leste",
      "value": "TIMOR_LESTE"
    },
    {
      "label": "Togo",
      "value": "TOGO"
    },
    {
      "label": "Tokelau",
      "value": "TOKELAU"
    },
    {
      "label": "Tonga",
      "value": "TONGA"
    },
    {
      "label": "Trinidad and Tobago",
      "value": "TRINIDAD_AND_TOBAGO"
    },
    {
      "label": "Tunisia",
      "value": "TUNISIA"
    },
    {
      "label": "Turkey",
      "value": "TURKEY"
    },
    {
      "label": "Turkmenistan",
      "value": "TURKMENISTAN"
    },
    {
      "label": "Turks and Caicos Islands",
      "value": "TURKS_AND_CAICOS_ISLANDS"
    },
    {
      "label": "Tuvalu",
      "value": "TUVALU"
    },
    {
      "label": "Uganda",
      "value": "UGANDA"
    },
    {
      "label": "Ukraine",
      "value": "UKRAINE"
    },
    {
      "label": "United Arab Emirates",
      "value": "UNITED_ARAB_EMIRATES"
    },
    {
      "label": "United Kingdom",
      "value": "UNITED_KINGDOM"
    },
    {
      "label": "United States",
      "value": "UNITED_STATES"
    },
    {
      "label": "United States Minor Outlying Islands",
      "value": "UNITED_STATES_MINOR_OUTLYING_ISLANDS"
    },
    {
      "label": "Uruguay",
      "value": "URUGUAY"
    },
    {
      "label": "Uzbekistan",
      "value": "UZBEKISTAN"
    },
    {
      "label": "Vanuatu",
      "value": "VANUATU"
    },
    {
      "label": "Venezuela",
      "value": "VENEZUELA"
    },
    {
      "label": "Vietnam",
      "value": "VIETNAM"
    },
    {
      "label": "Virgin Islands, British",
      "value": "VIRGIN_ISLANDS_BRITISH"
    },
    {
      "label": "Virgin Islands, U.S.",
      "value": "VIRGIN_ISLANDS_US"
    },
    {
      "label": "Wales",
      "value": "WALES"
    },
    {
      "label": "Wallis and Futuna",
      "value": "WALLIS_AND_FUTUNA"
    },
    {
      "label": "Western Sahara",
      "value": "WESTERN_SAHARA"
    },
    {
      "label": "Yemen",
      "value": "YEMEN"
    },
    {
      "label": "Zambia",
      "value": "ZAMBIA"
    },
    {
      "label": "Zimbabwe",
      "value": "ZIMBABWE"
    }
  ]
  