import { InputSwitch } from "primereact/inputswitch";
import React, { useState } from "react";

interface ListSwitchProps {
    value?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    onChange: (value:boolean) => void;
}

const SwitchBtn: React.FC<ListSwitchProps> = ({ value, disabled, hidden, onChange }) => {

    const [checked, setChecked] = useState<any>(() => {
        return value ?? false
    })

    const onChangeSwitch = (e: any) => {
        const _value = e?.target?.value
        setChecked(_value)
        onChange?.(_value)
    }

    return (
        <div style={{ minWidth: '45px' }}>
            <InputSwitch
                hidden={hidden ?? false}
                disabled={disabled}
                checked={checked}
                onChange={(e) => onChangeSwitch(e)}
            />
        </div>
    );
};

export default SwitchBtn;
