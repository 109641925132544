import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import images from "../../../assets/images/images";
import Footer from "../../../components/common/Footer";
import { AuthContext, AuthContextType } from "../../../contexts/AuthContext";
import { login } from "../../../services/authService";
import { MESSAGE } from "../../constants";

type FormValues = {
    email: string;
    password: string;
};

function Login() {
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [displayMsg, setDisplayMsg] = useState<boolean>(false);
    const { addUser } = useContext<AuthContextType>(AuthContext);
    const { control, handleSubmit, formState } = useForm<FormValues>({
        defaultValues: {
            email: "",
            password: "",
        },
    });
    const { errors } = formState;

    const onSubmit = async (data: any) => {
        login(data)
            .then((response) => {
                let user = response?.data
                addUser(user);
                navigate("/");
            })
            .catch((error) => {
                if (error.response?.status === 500) {
                    setErrorMsg(MESSAGE.error.system);
                } else {
                    error.response?.data ?
                        setErrorMsg(error.response.data?.message)
                        : setErrorMsg(MESSAGE.error.system);
                }
                setDisplayMsg(true);
            });
    };

    const handleCloseErrorPopup = () => {
        setDisplayMsg(false);
        setErrorMsg("");
    };

    return (
        <>
            <Dialog
                visible={displayMsg}
                header="시스템 메시지"
                style={{ width: "30vw" }}
                onHide={() => handleCloseErrorPopup()}
            >
                <div className=" flex flex-row m-0">
                    <WarningAmberIcon
                        className="mr-4"
                        sx={{ color: "red", fontSize: 60 }}
                    ></WarningAmberIcon>
                    <div className="mt-3">{errorMsg}</div>
                </div>
            </Dialog>
            <div className="min-h-screen flex flex-column justify-content-between">
                <div className="h-full w-full text-center px-6 pt-6 flex flex-column">
                    <img
                        src={images.logo}
                        className="h-4rem mt-8"
                        alt="EVSIS"
                    />
                    <div className="flex flex-column align-items-center gap-4 mt-8">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="field grid w-30rem">
                                <label
                                    htmlFor="email"
                                    className="col-4 text-black-alpha-90 mb-0"
                                >
                                    <i className="pi pi-fw pi-user mx-1 text-lg"></i>
                                    아이디/이메일
                                </label>
                                <Controller
                                    name="email"
                                    control={control}
                                    rules={{
                                        required:
                                            "이메일이 필요합니다. 확인하시고 재시도 부탁드립니다.",
                                        pattern: {
                                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/i,
                                            message:
                                                "이메일 형식이 잘못되었습니다. 확인하시고 재시도 부탁드립니다.",
                                        },
                                    }}
                                    render={({ field, fieldState }) => (
                                        <InputText
                                            type="text"
                                            id={field.name}
                                            {...field}
                                            autoFocus
                                            className={classNames(
                                                {
                                                    "p-invalid":
                                                        fieldState.error,
                                                },
                                                "col py-2 text-lg appearance-none outline-none"
                                            )}
                                        />
                                    )}
                                />
                            </div>
                            {errors.email && (
                                <div className="grid w-30rem">
                                    <div className="col-4"></div>
                                    <small className="col p-error text-left	">
                                        {errors.email.message}
                                    </small>
                                </div>
                            )}
                            <div className="field grid w-30rem">
                                <label
                                    htmlFor="password"
                                    className="col-4 text-black-alpha-90 mb-0"
                                >
                                    <i className="pi pi-fw pi-lock mx-1 text-lg"></i>
                                    비밀번호
                                </label>
                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required:
                                            "비밀번호가 필요합니다. 확인하시고 재시도 부탁드립니다.",
                                    }}
                                    render={({ field, fieldState }) => (
                                        <Password
                                            id={field.name}
                                            {...field}
                                            type="password"
                                            maxLength={16}
                                            feedback={false}
                                            toggleMask
                                            className={classNames(
                                                {
                                                    "p-invalid":
                                                        fieldState.error,
                                                },
                                                "w-20rem appearance-none outline-none"
                                            )}
                                            inputClassName="w-20rem outline-none"
                                            inputStyle={{ padding: "0.5rem" }}
                                        />
                                    )}
                                />
                            </div>
                            {errors.password && (
                                <div className="grid w-30rem">
                                    <div className="col-4"></div>
                                    <small className="col p-error text-left	">
                                        {errors.password.message}
                                    </small>
                                </div>
                            )}
                            <div className="flex justify-content-end field grid w-30rem">
                                <Button
                                    label="로그인"
                                    type="submit"
                                    className="w-20rem"
                                />
                            </div>
                            <div className="flex justify-content-end field grid w-30rem">
                                <Link to="/auth/forgot-password">
                                    <Button
                                        label="비밀번호 찾기"
                                        type="button"
                                        className="w-20rem"
                                        severity="secondary"
                                    />
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Login;
