import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { format } from "date-fns";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { createTopic } from "../../../services/topicService";
import { CATEGORIES_TYPE, DATE, PATH, STATUS, TOPIC_TYPE_ENUM, TYPE } from "../constants";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Editor } from "primereact/editor";
import { Fieldset } from "primereact/fieldset";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

import { Checkbox } from "primereact/checkbox";
import { Panel } from "primereact/panel";
import { useApp } from "../../../hooks/useApp";
import StringUtils from "../../../utils/stringUtils";
import ValidationUtils from "../../../utils/validationUtils";
import { LABEL, MESSAGE } from "../../constants";
import ChipsInput from "../common/Chips";
import ThumbnailInput from "../common/Thumbnail";
import HeaderEditor from "../../../components/common/HeaderEditor";


const TopicNew: React.FC<any> = () => {
    const app = useApp()

    const navigate = useNavigate();

    const [optionLangNew, setOptionLangNew] = useState({
        EN: false,
        KR: false
    })

    const { handleSubmit, control, watch, setValue } = useForm({
        defaultValues: {
            titleKr: '',
            titleEn: '',
            topicType: { name:'', code:'' },
            sortOnTop: 0,
            startDate: new Date(),
            endDate: null,
            descriptionKr: "",
            descriptionEn: "",
            hashTagsKr: [],
            hashTagsEn: [],
            thumbnailKr: "",
            thumbnailEn: "",
            statusKr: {},
            statusEn: {},
            category: {},
        },
    });

    const watchTitleKR = watch("titleKr");
    const watchTitleEN = watch("titleEn");
    const watchDesKR = watch("descriptionKr");
    const watchDesEN = watch("descriptionEn");
    const watchTopicType = watch("topicType");

    const showCategories = useMemo(()=>{
        return watchTopicType.code === TOPIC_TYPE_ENUM.faq;
    },[watchTopicType]);

    useEffect(() => {
        const valueCategory = showCategories ? CATEGORIES_TYPE[4] : {};
        setValue('category', valueCategory);
    },[showCategories]);

    const shouldDisableAutoFill = useCallback(() => {
        let check = false
        const isTitle = watchTitleEN?.trim() ?? '';
        const isDes = watchDesEN?.trim() ?? '';
        if (isTitle && isDes) {
            check = true
        }
        return check;
    }, [watchDesEN, watchTitleEN]);

    const onAutoFill = useCallback(
        (event: BaseSyntheticEvent) => {
            event.preventDefault();

            const _watchTitleEN = watchTitleEN?.trim() ?? "";
            const _watchDesEN = watchDesEN?.trim() ?? "";

            if (!_watchTitleEN) {
                setValue("titleEn", watchTitleKR);
            }
            if (!_watchDesEN) {
                setValue("descriptionEn", watchDesKR);
            }
        },
        [watchDesKR, watchTitleKR, watchDesEN, watchTitleEN]
    );

    const isValidDataNew = (values: any) => {
        const _isPopup = values?.topicType?.code === TOPIC_TYPE_ENUM.popup;
        const _topicType = values?.topicType?.code;

        const _titleKr = values?.titleKr?.trim();
        const _titleEn = values?.titleEn?.trim();

        const _descriptionKr = values?.descriptionKr?.trim();
        const _descriptionEn = values?.descriptionEn?.trim();

        const _statusKr = values?.statusKr?.code;
        const _statusEn = values?.statusEn?.code;

        const NEW_TOPIC_VALUE = {
            EN: {
                isSelect: optionLangNew.EN,
                status: _statusEn,
                title: _titleEn,
                description: _descriptionEn,
                thumbnail: values?.thumbnailEn,
            },
            KR: {
                isSelect: optionLangNew.KR,
                status: _statusKr,
                title: _titleKr,
                description: _descriptionKr,
                thumbnail: values?.thumbnailKr,
            },
        };

        const showErrorNew = (typeErr: "error" | "success" | "info" | "warn" | undefined, message: string) => {
            app.showToast({
                type: typeErr,
                message: message,
            });
        };

        if (!_topicType) {
            app.showToast({
                type: 'error',
                message: MESSAGE.validate.required.replace('{field}', LABEL.topic.type),
            });
            return false;
        }

        if (!optionLangNew.EN && !optionLangNew.KR) {
            app.showToast({
                type: 'error',
                message: MESSAGE.chooseLanguage,
            });
            return false;
        }

        const validateAndShowErrorNew = (topicData: any, lang: 'EN' | 'KR') => {
            const errors = ValidationUtils.validateContentTopic(topicData, lang, _isPopup);
            if (Object.keys(errors).length > 0) {
                const keys = Object.keys(errors);
                const message = errors[keys[0]];
                showErrorNew('error', message);
                return false;
            }
            return true;
        };

        if (optionLangNew.KR && !validateAndShowErrorNew(NEW_TOPIC_VALUE.KR, 'KR')) {
            return false;
        }

        if (optionLangNew.EN && !validateAndShowErrorNew(NEW_TOPIC_VALUE.EN, 'EN')) {
            return false;
        }

        return true;
    };

    const submit = (values: any) => {
        const _isValid = isValidDataNew(values)
        if (!_isValid) {
            return;
        }

        const _valuesKR = StringUtils.createLanguageValues(values, "Kr");
        const _valuesEN = StringUtils.createLanguageValues(values, "En");

        const _newValue = {
            topicType: values?.topicType?.code || "",
            category: values?.category?.code || "",
            sortOnTop: values?.sortOnTop ? 1 : 0,
            startDate: values?.startDate && format(values?.startDate, DATE.default),
            endDate: values?.endDate && format(values?.endDate, DATE.default),
            ...(_valuesKR && optionLangNew.KR ? _valuesKR : {}),
            ...(_valuesEN && optionLangNew.EN ? _valuesEN : {}),
        };

        const _formData = StringUtils.convertObjectToFormData(_newValue)

        if (values?.thumbnailEn) {
            _formData.set("thumbnailEn", values?.thumbnailEn);
        }

        if (values?.thumbnailKr) {
            _formData.set("thumbnailKr", values?.thumbnailKr);
        }

        createTopic(_formData)
            .then((res) => {
                if (res?.status === 200) {
                    app.showToast({
                        type: 'success',
                        message: res.data.message
                    })
                    navigate(PATH.index);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    };

    const onChangeOptionLangNew = (e: any) => {
        let _name = e?.target?.name
        let _checked = e?.checked
        setOptionLangNew((prev: any) => {
            return {
                ...prev,
                [_name]: _checked
            }
        });
    }

    const headerEditor = HeaderEditor();
    
    return (
        <>
            <Fieldset legend={LABEL.common.addNew} className="w-fieldset">
                <div className="flex justify-content-end mb-3 gap-2">
                    <Button
                        label="한글 복사"
                        onClick={(e) => onAutoFill(e)}
                        disabled={shouldDisableAutoFill()}
                        icon="pi pi-copy"
                        type="button"
                    />
                    <Link to={PATH.index}>
                        <Button
                            label={LABEL.common.back}
                            icon="pi pi-arrow-left"
                            type="button"
                            severity="secondary"
                        />
                    </Link>
                </div>

                <form onSubmit={handleSubmit(submit)} className="flex flex-column gap-6">
                    <Panel header={LABEL.topic.generalInfo}>
                        <div className=" flex flex-wrap w-full">
                            <div className="flex align-items-center gap-2 lg:col-6 md:col-8 sm:col-12">
                                {/* topicType */}
                                <label
                                    htmlFor="type"
                                    className="min-w-label-3"
                                >
                                    {LABEL.topic.type}
                                    <span className="text-red-600 pl-1">*</span>
                                </label>
                                <Controller
                                    name="topicType"
                                    control={control}
                                    render={({
                                        field: { name, value, onBlur, onChange },
                                    }) => (
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={TYPE}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e.value)}
                                            className={"w-full"}
                                            disabled={false}
                                        />
                                    )}
                                />
                                {/* type */}
                            </div>

                            <div className="flex align-items-center gap-2 lg:col-3 md:col-4 sm:col-12">
                                {/* sortOnTop */}
                                <label
                                    htmlFor="sortOnTop"
                                    className="min-w-label-3"
                                >
                                    {LABEL.topic.sortOnTop}
                                </label>
                                <Controller
                                    name="sortOnTop"
                                    control={control}
                                    render={({
                                        field: { name, value, onChange, ref },
                                    }) => (
                                        <InputSwitch
                                            inputId={name}
                                            checked={value == 0 ? false : true}
                                            inputRef={ref}
                                            onChange={onChange}
                                            disabled={false}
                                        />
                                    )}
                                />
                                {/* sortOnTop */}
                            </div>

                            { showCategories && (
                            <div className="flex align-items-center gap-2 lg:col-3 md:col-8 sm:col-12">
                                {/* Category */}
                                <label
                                    htmlFor="sortOnTop"
                                    className="min-w-label-3"
                                >
                                    {LABEL.topic.category}
                                </label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({
                                        field: { name, value, onBlur, onChange },
                                    }) => (
                                        <Dropdown
                                        id={name}
                                        value={value}
                                        options={CATEGORIES_TYPE}
                                        optionLabel="name"
                                        onBlur={onBlur}
                                        onChange={(e: any) => onChange(e.value)}
                                        className={"w-full"}
                                        disabled={false}
                                    />                                        
                                    )}
                                />
                                {/* Category */}
                            </div>
                            )}

                            <div className="flex align-items-center gap-2 lg:col-6 md:col-8 sm:col-12">
                                {/* startDate */}
                                <label
                                    htmlFor="from"
                                    className="min-w-label-3"
                                >
                                    {LABEL.topic.startDate}
                                </label>
                                <Controller
                                    name="startDate"
                                    control={control}
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Calendar
                                            inputId={name}
                                            value={value}
                                            dateFormat="yy-mm-dd"
                                            onChange={(e: any) => onChange(e.value)}
                                            className={"w-full"}
                                            disabled={false}
                                            readOnlyInput
                                        />
                                    )}
                                />
                                {/* startDate */}
                            </div>

                            <div className="flex align-items-center gap-2 lg:col-6 md:col-8 sm:col-12">
                                {/* endDate */}
                                <label
                                    htmlFor="to"
                                    className="min-w-label-3"
                                >
                                    {LABEL.topic.endDate}
                                </label>
                                <Controller
                                    name="endDate"
                                    control={control}
                                    render={({
                                        field: { name, value, onChange },
                                    }) => (
                                        <Calendar
                                            inputId={name}
                                            value={value}
                                            dateFormat="yy-mm-dd"
                                            onChange={(e: any) => onChange(e.value)}
                                            className={"w-full"}
                                            disabled={false}
                                            readOnlyInput
                                        />
                                    )}
                                />
                                {/* endDate */}
                            </div>
                        </div>
                    </Panel>

                    <Panel header={LABEL.topic.descriptionInfo}>
                        <div className="flex flex-wrap w-full">
                            <div className="flex align-items-center flex-column gap-3 lg:col-6 md:col-8 sm:col-12">
                                {/* checkBoxKR */}
                                <div className="flex align-items-center w-full mb-5">
                                    <Checkbox
                                        inputId="checkBoxKR"
                                        name="KR"
                                        value={optionLangNew?.KR}
                                        onChange={onChangeOptionLangNew}
                                        checked={optionLangNew?.KR}
                                    />
                                    <label htmlFor="checkBoxKR" className="ml-2">Korean</label>
                                </div>
                                {/* checkBoxKR */}

                                {/* titleKr */}
                                <div className="flex align-items-center gap-2 w-full">
                                    <label
                                        htmlFor="titleKr"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.titleKR}
                                        <span className="text-red-600 pl-1">*</span>
                                    </label>
                                    <Controller
                                        name="titleKr"
                                        control={control}
                                        render={({
                                            field: { name, value, onBlur, onChange },
                                            fieldState: { error },
                                        }) => (
                                            <InputText
                                                id={name}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                className={classNames(
                                                    { "p-invalid": error },
                                                    "w-full"
                                                )}
                                                disabled={!optionLangNew?.KR}
                                            />
                                        )}
                                    />
                                </div>
                                {/* titleKr */}

                                {/* statusKr */}
                                <div className="flex align-items-center gap-2 w-full">
                                    <label
                                        htmlFor="statusKr"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.statusKR}
                                        <span className="text-red-600 pl-1">*</span>
                                    </label>
                                    <Controller
                                        name="statusKr"
                                        control={control}
                                        render={({
                                            field: { name, value, onBlur, onChange },
                                        }) => (
                                            <Dropdown
                                                id={name}
                                                value={value}
                                                options={STATUS}
                                                optionLabel="name"
                                                onBlur={onBlur}
                                                onChange={(e: any) => onChange(e.value)}
                                                className={"w-full"}
                                                disabled={!optionLangNew?.KR}
                                            />
                                        )}
                                    />
                                </div>
                                {/* statusKr */}


                                {/* descriptionKr */}
                                <div className="flex align-items-start gap-2 w-full">
                                    <label
                                        htmlFor="descriptionKr"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.descriptionKR}
                                    </label>
                                    <Controller
                                        name="descriptionKr"
                                        control={control}
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <Editor
                                                id={name}
                                                name={name}
                                                value={value}
                                                onTextChange={(e) =>
                                                    onChange(e?.htmlValue)
                                                }
                                                className={`w-full ${!optionLangNew?.KR ? 'disable-custom' : ''}`}
                                                style={{ height: "200px" }}
                                                headerTemplate={headerEditor}
                                            />
                                        )}
                                    />
                                </div>
                                {/* descriptionKr */}

                                {/* thumbnailKR */}
                                <div className="flex align-items-center gap-2 w-full">
                                    <label
                                        htmlFor="thumbnailKr"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.thumbnailKR}
                                    </label>
                                    <ThumbnailInput
                                        className={`w-full ${!optionLangNew?.KR ? 'disable-custom' : ''}`}
                                        isEdit={true}
                                        formProps={{
                                            name: "thumbnailKr",
                                            control,
                                            setValue,
                                        }}
                                    />
                                </div>
                                {/* thumbnailKR */}

                                {/* hashTagsKr */}
                                <div className="flex align-items-start gap-2 w-full">
                                    <label
                                        htmlFor="hashTagsKr"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.hashTagsKR}
                                    </label>
                                    <ChipsInput
                                        className={`w-full ${!optionLangNew?.KR ? 'disable-custom' : ''}`}
                                        isEdit={true}
                                        formProps={{
                                            name: "hashTagsKr",
                                            control,
                                            watch,
                                            setValue
                                        }}
                                    />
                                </div>
                                {/* hashTagsKr */}

                            </div>

                            <div className="flex align-items-center flex-column gap-3 lg:col-6 md:col-8 sm:col-12">
                                {/* checkBoxEN */}
                                <div className="flex align-items-center w-full mb-5">
                                    <Checkbox
                                        inputId="checkBoxKR"
                                        name="EN"
                                        value={optionLangNew?.EN}
                                        onChange={onChangeOptionLangNew}
                                        checked={optionLangNew?.EN}
                                    />
                                    <label htmlFor="checkBoxEN" className="ml-2">English</label>
                                </div>
                                {/* checkBoxKR */}

                                {/* titleEn */}
                                <div className="flex align-items-center gap-2 w-full">
                                    <label
                                        htmlFor="titleEn"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.titleEN}
                                        <span className="text-red-600 pl-1">*</span>
                                    </label>
                                    <Controller
                                        name="titleEn"
                                        control={control}
                                        render={({
                                            field: { name, value, onBlur, onChange },
                                        }) => (
                                            <InputText
                                                id={name}
                                                value={value}
                                                onBlur={onBlur}
                                                onChange={onChange}
                                                className={"w-full"}
                                                disabled={!optionLangNew.EN}
                                            />
                                        )}
                                    />
                                </div>
                                {/* titleEn */}

                                {/* statusEn */}
                                <div className="flex align-items-center gap-2 w-full">
                                    <label
                                        htmlFor="statusEn"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.statusEN}
                                        <span className="text-red-600 pl-1">*</span>
                                    </label>
                                    <Controller
                                        name="statusEn"
                                        control={control}
                                        render={({
                                            field: { name, value, onBlur, onChange },
                                        }) => (
                                            <Dropdown
                                                id={name}
                                                value={value}
                                                options={STATUS}
                                                optionLabel="name"
                                                onBlur={onBlur}
                                                onChange={(e: any) => onChange(e.value)}
                                                className={"w-full"}
                                                disabled={!optionLangNew.EN}
                                            />
                                        )}
                                    />
                                </div>
                                {/* statusEn */}

                                {/* descriptionEn */}
                                <div className="flex align-items-start gap-2 w-full">
                                    <label
                                        htmlFor="descriptionEn"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.descriptionEN}
                                    </label>
                                    <Controller
                                        name="descriptionEn"
                                        control={control}
                                        render={({
                                            field: { name, value, onChange },
                                        }) => (
                                            <Editor
                                                id={name}
                                                name={name}
                                                value={value}
                                                onTextChange={(e) =>
                                                    onChange(e?.htmlValue)
                                                }
                                                className={`w-full ${!optionLangNew?.EN ? 'disable-custom' : ''}`}
                                                style={{ height: "200px" }}
                                                readOnly={false}
                                                headerTemplate={headerEditor}
                                            />
                                        )}
                                    />
                                </div>
                                {/* descriptionEn */}

                                {/* thumbnailEN */}
                                <div className="flex align-items-center gap-2 w-full">
                                    <label
                                        htmlFor="thumbnailEn"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.thumbnailEN}
                                    </label>
                                    <ThumbnailInput
                                        isEdit={true}
                                        className={`w-full ${!optionLangNew?.EN ? 'disable-custom' : ''}`}
                                        formProps={{
                                            name: "thumbnailEn",
                                            control,
                                            setValue,
                                        }}
                                    />
                                </div>
                                {/* thumbnailEN */}

                                {/* hashTagsEn */}
                                <div className="flex align-items-start gap-2 w-full">
                                    <label
                                        htmlFor="hashTagsEn"
                                        className="min-w-label-3"
                                    >
                                        {LABEL.topic.hashTagsEN}
                                    </label>
                                    <ChipsInput
                                        isEdit={true}
                                        className={`w-full ${!optionLangNew?.EN ? 'disable-custom' : ''}`}
                                        formProps={{
                                            name: "hashTagsEn",
                                            control,
                                            watch,
                                            setValue
                                        }}
                                    />
                                </div>
                                {/* hashTagsEn */}

                            </div>
                        </div>

                        <div className="flex flex-wrap justify-content-between align-items-start">
                            <div className="text-500 lg:col-8 md:col-8 sm:col-12">
                                <strong>주의:</strong>
                                <ul className="mt-1">
                                    <li>
                                        "게시" 상태를 선택하면 해당 게시물이 EVSIS
                                        홈페이지에 즉시 표시됩니다.
                                    </li>
                                    <li>
                                        {
                                            `해시태그 적용하기위해 해시태그 하나씩 Enter부탁드립니다.
                                        해시태그가 언어별로 최대 3개만 적용됩니다. 예시: abc -> enter`
                                        }
                                    </li>
                                    <li>
                                        링크 삽입 시 맞은 형식을 http(s)://www 포함 입력 해주시길 바랍니다. 예시 : https://www.youtube.com
                                    </li>
                                </ul>
                            </div>
                            <div className="flex align-items-center justify-content-end align-self-end gap-2 lg:col-4 md:col-4 sm:col-12">
                                <Link to="/topic-management">
                                    <Button
                                        label={LABEL.common.cancel}
                                        type="button"
                                        severity="secondary"
                                    />
                                </Link>
                                <Button label={LABEL.common.save} type="submit" />
                            </div>
                        </div>
                    </Panel>
                </form>
            </Fieldset>
        </>
    );
};

export default TopicNew;
