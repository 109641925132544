import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from "react";

import { format } from "date-fns";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useApp } from "../../../hooks/useApp";
import { getJobDetails, updateJobStatus } from "../../../services/jobService";
import ValidationUtils from "../../../utils/validationUtils";
import { LABEL, MESSAGE } from "../../constants";

const JobDetails: React.FC<any> = () => {
    const app = useApp()
    const { id } = useParams();

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            jobId: null,
            jobTitle: '',
            jobDetail: '',
            status: {},
            postingAt: '',
            expirationAt: '',
            openingAt: '',
            regId: '',
            jobExpired: true,
        }
    });

    const [data, setData] = useState<any>({});
    const [expired, setExpired] = useState<boolean>(false);
    const [isEdit, setEdit] = useState<boolean>(false);

    const STATUS = [
        { name: LABEL.common.active, code: "ACTIVE" },
        { name: LABEL.common.inActive, code: "IN_ACTIVE" },
    ];

    const formatDate = useCallback((timestamp: number) => {
        const _date = new Date(timestamp * 1000).toLocaleString("en-US", {
            timeZone: "Asia/Seoul",
        });
        return format(new Date(_date), "yyyy-MM-dd");
    }, []);

    const submit = async (values: any) => {
        if (Object.keys(values).length) {
            const status = values.status.code;

            const params = {
                jobId: id ?? '',
                status: status,
            };

            try {
                await updateJobStatus(params).then((res) => {
                    const status = res.status;

                    if (status === 200) {
                        app.showToast({
                            type: 'success',
                            message: MESSAGE.success.edit
                        })
                    }
                });
            } catch (error: any) {
                const message =
                    error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            }
        }
    };

    useEffect(() => {
        if (id) {
            getJobDetails(id)
                .then((res) => {
                    const status = res.status;

                    if (status === 200) {
                        setData(res.data);
                    }
                })
                .catch((error: any) => {
                    const message = error?.response?.data?.message || error?.message;
                    app.showToast({
                        type: 'error',
                        message: message
                    })
                })
        }
    }, [id]);

    useEffect(() => {
        // initial values
        if (Object.keys(data).length) {
            setValue("jobId", data.jobId ?? '');
            setValue("jobTitle", data.jobTitle ?? '');

            const openingAt = data.openingAt;
            setValue("openingAt", openingAt ? formatDate(openingAt) : '');

            const expirationAt = data.expirationAt;
            setValue("expirationAt", expirationAt ? formatDate(expirationAt) : '');

            setValue("regId", data.regId ?? '');
            setValue("jobDetail", data.jobDetail ?? '');

            const _status = data.status;
            setValue("status", _status === "ACTIVE" ? STATUS[0] : STATUS[1]);

            const _isExpired = ValidationUtils.isExpirationDate(expirationAt)
            setExpired(_isExpired)

            const _jobExpired = data.jobExpired;
            setValue("jobExpired",_jobExpired === "ACTIVE" ? true:false);
        }
    }, [data]);

    const toggleEdit = useCallback(
        (event: BaseSyntheticEvent, edit: boolean) => {
            event.preventDefault();
            setEdit(() => {
                edit && app.showToast({
                    type: "success",
                    message: MESSAGE.enableEdit
                })
                return edit;
            });
        },
        [setEdit]
    );
    return (
        <>
            <Fieldset legend={LABEL.job.detail} className="w-fieldset">
                <div className="flex justify-content-end mb-3 gap-2">
                    <Link to="/job">
                        <Button
                            label={LABEL.common.back}
                            icon="pi pi-arrow-left"
                            type="button"
                            severity="secondary"
                        />
                    </Link>
                    <Button
                        label={LABEL.common.edit}
                        icon="pi pi-pencil"
                        type="button"
                        onClick={(e) => toggleEdit(e, true)}
                        disabled={isEdit}
                    />
                </div>
                <form onSubmit={handleSubmit(submit)}>
                    <div className="flex flex-wrap w-full">
                        {/* jobId */}
                        <div className="flex align-items-center gap-2 lg:col-8 md:col-6 sm:col-12">
                            <label
                                htmlFor="jobId"
                                className="min-w-label-2"
                            >
                                {LABEL.job.id}
                            </label>
                            <Controller
                                name="jobId"
                                control={control}
                                render={({
                                    field: {
                                        name,
                                        ref,
                                        value,
                                        onBlur,
                                        onChange,
                                    },
                                }) => (
                                    <InputNumber
                                        id={name}
                                        inputRef={ref}
                                        value={value}
                                        onBlur={onBlur}
                                        onValueChange={(e: any) => onChange(e)}
                                        useGrouping={false}
                                        className={"w-full"}
                                        disabled
                                        inputStyle={{ width: '100%' }}
                                    />
                                )}
                            />
                        </div>
                        {/* jobId */}

                        {/* openingAt - creeated date */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <label
                                htmlFor="openingAt"
                                className="min-w-label-2"
                            >
                                {LABEL.common.createDate}
                            </label>
                            <Controller
                                name="openingAt"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* openingAt - creeated date */}

                        {/* jobTitle */}
                        <div className="flex align-items-center gap-2 lg:col-8 md:col-6 sm:col-12">
                            <label
                                htmlFor="jobTitle"
                                className="min-w-label-2"
                            >
                                {LABEL.job.title}
                            </label>
                            <Controller
                                name="jobTitle"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* jobTitle */}

                        {/* expirationAt */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <label
                                htmlFor="expirationAt"
                                className="min-w-label-2"
                            >
                                {LABEL.job.expirationAt}
                            </label>
                            <Controller
                                name="expirationAt"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* expirationAt */}

                        {/* regId */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <label
                                htmlFor="regId"
                                className="min-w-label-2"
                            >
                                {LABEL.job.createdById}
                            </label>
                            <Controller
                                name="regId"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        disabled
                                        className="w-full"
                                    />
                                )}
                            />
                        </div>
                        {/* regId */}

                        {/* status */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <label
                                htmlFor="status"
                                className="min-w-label-2"
                            >
                                {LABEL.common.status}
                            </label>
                            <Controller
                                name="status"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <Dropdown
                                        id={name}
                                        value={value}
                                        options={STATUS}
                                        optionLabel="name"
                                        onBlur={onBlur}
                                        onChange={(e: any) => onChange(e.value)}
                                        className="w-full"
                                          disabled={!isEdit}
                                    />
                                )}
                            />
                        </div>
                        {/* status */}
                        {/* expired */}
                        <div className="flex align-items-center gap-2 lg:col-4 md:col-6 sm:col-12">
                            <label
                                htmlFor="expired"
                                className="min-w-label-2"
                            >
                                {LABEL.job.expired}
                            </label>
                            <Controller
                                    name="jobExpired"
                                    control={control}
                                    render={({
                                        field: { name, value, onChange, ref },
                                    }) => (
                                        <InputSwitch
                                            id={name}
                                            checked={value}
                                            inputRef={ref}
                                            onChange={onChange}
                                            disabled
                                        />
                                    )}
                                />
                        </div>
                        {/* expired */}
                        {/* jobDetail */}
                        <div className="flex align-items-start gap-2 col-12">
                            <label
                                htmlFor="jobDetail"
                                className="min-w-label-2"
                            >
                                {LABEL.job.description}
                            </label>
                            <Controller
                                name="jobDetail"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputTextarea
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                        rows={7}
                                    />
                                )}
                            />
                        </div>
                        {/* jobDetail */}

                    </div>

                    <div className="flex justify-content-end gap-2 col-12">
                        <Link to="/job">
                            <Button
                                label={LABEL.common.cancel}
                                type="button"
                                severity="secondary"
                            />
                        </Link>
                        <Button label="적용" type="submit" />
                    </div>
                </form>
            </Fieldset>
        </>
    );
};

export default JobDetails;
