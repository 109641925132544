export const PATH = {
    index: "/user",
    new:"/user/new",
    details:'/user/details'
};

export const STATUS_OPTION = [
    { name: "활성", code: false },
    { name: "비활성", code: true },
];

export const ROLES_OPTION = [
    { name: "고급 관리자", code: "SUPER_ADMIN" },
    { name: "관리자", code: "ROLE_ADMIN" },
];
