import React, {
    BaseSyntheticEvent,
    useCallback,
    useEffect,
    useState
} from "react";

import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import Paging from "../../../components/common/Paging";
import { useApp } from "../../../hooks/useApp";
import { searchJobById, updateJobStatus } from "../../../services/jobService";
import { ListContent } from "../../../types/JobTypes";
import StringUtils from "../../../utils/stringUtils";
import { LABEL } from "../../constants";
import JobsFilter from "./Filter";
import JobsList from "./List";
import NewModal from "./New";

const JobList: React.FC<any> = () => {
    const app = useApp()

    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [content, setContent] = useState<ListContent[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [isAddNew, setAddNew] = useState<boolean>(false);
    const [pagingSpec, setPagingSpec] = useState<any>({});
    const [query, setQuery] = useState<any>({});

    const getData = useCallback((values: any) => {
        let params: any = {
            ...query,
            page: page,
            size,
            lang: "KR",
        };

        let newQuery = StringUtils.convertObjectToQueryString(params)

        searchJobById(newQuery)
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data || {}
                    setContent(data?.content);
                    setTotal(data?.paginationSpec?.totalElements);
                    setPagingSpec(data?.paginationSpec);
                }
            })
            .catch((error: any) => {
                let message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    }, [page, size]);

    const onReset = useCallback(() => {
        setPage(0);
        setSize(10);
        setQuery({})
        getData({});
    }, []);

    const onFilter = useCallback((values: any) => {
        if (!Object.keys(values).length) return;

        let params: any = {
            page: 0,
            size,
            jobId: values?.id || '',
            status: values.status?.code || ''
        };

        let newQuery = StringUtils.convertObjectToQueryString(params);

        searchJobById(newQuery)
            .then((res) => {
                let body = res?.data || [];
                setContent(body?.content);
                setTotal(body?.paginationSpec?.totalElements);
                setPagingSpec(body?.paginationSpec);
            })
            .catch((error: any) => {
                let message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
        setQuery(params)
        setPage(0);
    }, [size]);

    const toggleAddNew = useCallback(
        (event: BaseSyntheticEvent, isOpen: boolean) => {
            event.preventDefault();
            setAddNew(isOpen);
        },
        []
    );

    const onUpdateRecord = (data: ListContent, value: boolean) => {
        const params = {
            ...data,
            status: value ? 'ACTIVE' : 'IN_ACTIVE'
        }
        updateJobStatus(params)
            .then((res) => {
                const status = res.status;
                if (status === 200) {
                    getData({})
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    }

    const onAddSuccess = useCallback(() => {
        setAddNew(false);
        getData({});
    }, []);

    useEffect(() => {
        getData({});
    }, [page, size]);

    return (
        <>
            <p className="text-lg font-bold">{LABEL.job.recruitmentInfo}</p>
            <JobsFilter onFilter={onFilter} onReset={onReset} />

            <Fieldset legend={LABEL.job.information} className="mt-3 w-fieldset">
                <div className="flex justify-content-end align-items-center mb-3">
                    <Button
                        label={LABEL.common.addNew}
                        icon="pi pi-plus"
                        onClick={(e) => toggleAddNew(e, true)}
                    />
                </div>
                <JobsList data={content} pagingSpec={pagingSpec} onUpdateRecord={onUpdateRecord}/>

                <Paging
                    total={total}
                    currentPage={page}
                    rows={size}
                    onChangePage={(e: number) => setPage(e)}
                    onChangeSize={(e: number) => setSize(e)}
                />
            </Fieldset>
            <NewModal
                visible={isAddNew}
                onHide={() => setAddNew(false)}
                onSuccess={() => onAddSuccess()}
            />
        </>
    );
};

export default JobList;
