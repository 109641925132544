import React, { useEffect, useRef, useState } from "react";

import { Control, Controller, UseFormWatch } from "react-hook-form";

import { Chips } from "primereact/chips";
import { RadioButton } from 'primereact/radiobutton';

interface ChipsProps {
    isEdit: boolean;
    className?: string,
    formProps: {
        control: Control<any>;
        name: string;
        watch: UseFormWatch<any>;
        setValue?: any;
    };
}

const ChipsInput: React.FC<ChipsProps> = ({ isEdit, className, formProps }) => {
    const { control, name, watch, setValue } = formProps;

    const _initValue = useRef<any>()

    const watchSwitch = watch(`${name}`);

    const [valueState, setValueState] = useState(() => {
        let check = watchSwitch?.length > 0
        return check
    })

    useEffect(() => {
        let check = watchSwitch?.length > 0
        setValueState(check)
    }, [watchSwitch])

    const customChip = (item: any) => {
        return (
            <div>
                <span>#{item}</span>
            </div>
        );
    };

    const onChangeRadio = (e: any) => {
        let check = e?.value
        if (!check) {
            _initValue.current = watchSwitch
            setValue(`${name}`, [] as any);
        }
        else if (_initValue.current?.length > 0) {
            setValue(`${name}`, _initValue.current);
        }
        setValueState(e.value)
    }

    return (
        <div className={`w-full flex flex-column gap-3 ${className ?? ''}`}>
            <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                    <RadioButton disabled={!isEdit} inputId="no" name="noBtn" value={false} onChange={onChangeRadio} checked={!valueState} />
                    <label htmlFor="yes" className="ml-2">No</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton disabled={!isEdit} inputId="yes" name="yesBtn" value={true} onChange={onChangeRadio} checked={valueState} />
                    <label htmlFor="yes" className="ml-2">Yes</label>
                </div>
            </div>
            {
                valueState &&
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { name, value, onChange } }) => {
                        const _value = value.filter((each: string) => each)
                        return (
                            <Chips
                                id={name}
                                name={name}
                                value={_value}
                                onChange={(e) => onChange(e.value)}
                                itemTemplate={customChip}
                                className={"w-full"}
                                disabled={!isEdit}
                                max={3}
                            />
                        );
                    }}
                />
            }
        </div>
    );
};

export default ChipsInput;
