import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import React from "react";
import DetailBtn from "../../../components/common/DetailBtn";
import { ContactType } from "../../../types/ContactTypes";
import { LABEL } from "../../constants";
import { FORM_TYPE_ENUM, FORM_TYPE_LABEL, PATH } from "../constants";

interface ListProps {
    data: ContactType[];
    pagingSpec: any;
}

const ContactList: React.FC<ListProps> = ({ data, pagingSpec }) => {

    const renderTagFormType = (data: ContactType) => {
        let severityType = data?.formType == FORM_TYPE_ENUM.contact ? 'success' : 'warning' as any
        return (
            <Tag severity={severityType} value={FORM_TYPE_LABEL[data?.formType]} />
        )
    }

    const renderDetailBtn = (data: ContactType) => {
        return <DetailBtn path={PATH.details} param={data?.id} />
    }

    return (
        <DataTable value={data}>
            <Column
                header="#"
                body={(data: ContactType, props: ColumnBodyOptions) => {
                    const index = props.rowIndex;
                    const page = pagingSpec.page;
                    const size = pagingSpec.size;

                    return page * size + index + 1;
                }}
            />
            <Column field="language" header={LABEL.common.language} />
            <Column
                align={"center"}
                field="formType"
                header={LABEL.contact.formType}
                body={(data: ContactType) => { return renderTagFormType(data) }}
            />
            <Column field="fullName" header={LABEL.contact.fullName} />
            <Column field="company" header={LABEL.contact.company} />
            <Column field="email" header={LABEL.common.email}  align={"center"}/>
            <Column field="telephone" header={LABEL.contact.telephone} className="white-space-nowrap" align={"center"} />
            <Column field="mobiphone" header={LABEL.contact.mobilePhone} className="white-space-nowrap" align={"center"} />
            <Column field="postCode" header={LABEL.contact.postCode} align={"center"} />

            <Column
                header={LABEL.common.addDetail}
                align={"center"}
                body={(data: ContactType) => { return renderDetailBtn(data) }}
            />
        </DataTable>
    );
};

export default ContactList;
