import React, { useCallback, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { addNewJob } from "../../../services/jobService";
import { SubmitJob } from "../../../types/JobTypes";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Tooltip } from "primereact/tooltip";
import { useApp } from "../../../hooks/useApp";
import { LABEL, MESSAGE } from "../../constants";

interface NewModalProps {
    visible: boolean;
    onHide: () => void;
    onSuccess: () => void;
}

const NewModal: React.FC<NewModalProps> = ({ visible, onHide, onSuccess }) => {
    const app = useApp()

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const getFormErrorMessage = useCallback(
        (name: string) => {
            if (!Object.keys(errors).length) return null;

            const error: any = errors[name]?.message;

            if (!error) return null;

            return <div className="p-error text-xs mt-1">{error}</div>;
        },
        [errors]
    );

    const createTooltip = useMemo(() => {
        return (
            <Tooltip target=".new-tooltip" autoHide={false}>
                <div className="mb-2 font-light">
                    + Saramin 웹에서 정확한 공고 아이디를 입력해주시길 바랍니다.
                    입력된 아이디로 데이터를 가져옵니다.
                </div>

                <div>
                    + <strong>예시</strong>
                    <ul className="m-0 font-light">
                        <li>
                            Saramin 링크 :{" "}
                            <a
                                href="https://www.saramin.co.kr/zf_user/jobs/relay/view?isMypage=no&rec_idx=44213534"
                                target={"_blank"}
                                rel="noopener noreferrer"
                                className="text-white"
                            >
                                https://www.saramin.co.kr/zf_user/jobs/relay/view?isMypage=no&rec_idx=
                                <strong>44213534</strong>
                            </a>
                        </li>
                        <li>
                            입력할 정보 : <strong>44213534</strong> 공고
                            아이디입니다.
                        </li>
                    </ul>
                </div>
            </Tooltip>
        );
    }, []);

    const submit = async (values: any) => {
        const data: SubmitJob = {
            jobId: `${values.jobId}`,
        };

        try {
            await addNewJob(data).then((res) => {
                const status = res.status;

                if (status === 200) {
                    app.showToast({
                        type: "success",
                        message: "데이터를 가져오기 성공했습니다. 채용목록 및 채용내역에서 확인해주세요."
                    })
                    if (typeof onSuccess === "function") {
                        onSuccess();
                        control._reset();
                    }
                }
            });
        } catch (error: any) {
            const message = error?.response?.data?.message || error?.message;
            app.showToast({
                type: "error",
                message: message
            })
        }
    };

    return (
        <>
            <Dialog
                header={LABEL.common.addNew}
                style={{ width: "50vw", maxWidth: "480px" }}
                visible={visible}
                onHide={() =>{
                    onHide();
                    control._reset();
                }}
            >
                <form onSubmit={handleSubmit(submit)}>
                    {/* jobId */}
                    <div className="flex flex-column">
                        <label
                            className="flex gap-2 align-items-center mb-2"
                            htmlFor="jobId"
                        >
                            <span>{LABEL.job.inputId}</span>
                            <i className="pi pi-info-circle text-xs cursor-pointer new-tooltip" />
                        </label>
                        <Controller
                            name="jobId"
                            control={control}
                            rules={{ required: MESSAGE.validate.required.replace('{field}', LABEL.job.id) }}
                            render={({
                                field: { name, ref, value, onBlur, onChange },
                                fieldState,
                            }) => (
                                <>
                                    <InputNumber
                                        id={name}
                                        inputRef={ref}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={(e) => {
                                            return onChange(e.value);
                                        }}
                                        useGrouping={false}
                                        min={0}
                                        inputClassName={classNames(
                                            {
                                                "p-invalid": fieldState.error,
                                            },
                                            "w-full"
                                        )}
                                    />
                                    {getFormErrorMessage(name)}
                                </>
                            )}
                        />
                    </div>
                    {/* jobId */}

                    <div className="flex justify-content-end mt-3">
                        <Button
                            label={LABEL.common.getData}
                            icon="pi pi-search"
                            type="submit"
                        />
                    </div>
                </form>
                {createTooltip}
            </Dialog>
        </>
    );
};

export default NewModal;
