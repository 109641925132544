import { format } from "date-fns";
import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import React, { useCallback } from "react";
import DetailBtn from "../../../components/common/DetailBtn";
import SwitchBtn from "../../../components/common/SwitchBtn";
import { ListContent } from "../../../types/JobTypes";
import ValidationUtils from "../../../utils/validationUtils";
import { LABEL } from "../../constants";
import { PATH } from "../constants";

interface ListProps {
    data: ListContent[];
    pagingSpec: any;
    onUpdateRecord: (data: ListContent, value: boolean) => void | undefined
}

const JobsList: React.FC<ListProps> = ({ data, pagingSpec, onUpdateRecord }) => {
    const formatDate = useCallback((timestamp: number) => {
        const _date = new Date(timestamp * 1000).toLocaleString("en-US", {
            timeZone: "Asia/Seoul",
        });
        return format(new Date(_date), "yyyy-MM-dd");
    }, []);

    const createJobTag = useCallback((data: ListContent) => {
        const status = data.status
        if (!status) return null;

        const _active = status === "ACTIVE"
        const _status = _active ? LABEL.common.active : LABEL.common.inActive
        const _type = _active ? "success" : "warning";

        const onChangeSwitch = (value: any) => {
            onUpdateRecord(data, value)
        }

        return (
            <div className="flex align-items-center gap-2 justify-content-center">
                <Tag severity={_type} value={_status} />
                <SwitchBtn value={_active} onChange={onChangeSwitch} />
            </div>
        )
    }, []);

    const renderDetailBtn = (data: ListContent) => {
        return <DetailBtn path={PATH.details} param={data?.jobId} />
    }

    return (
        <DataTable value={data}>
            <Column
                header="#"
                body={(data: ListContent, props: ColumnBodyOptions) => {
                    const index = props.rowIndex;
                    const page = pagingSpec.page;
                    const size = pagingSpec.size;

                    return page * size + index + 1;
                }}
            />
            <Column field="jobId" header={LABEL.job.id} />
            <Column field="jobTitle" header={LABEL.job.title} />
            <Column field="regId" header={LABEL.job.registrant} />
            <Column
                header={LABEL.common.createDate}
                align={"center"}
                body={(data: ListContent) => {
                    return formatDate(data.openingAt);
                }}
            />
            <Column
                header={LABEL.job.expirationAt}
                align={"center"}
                body={(data: ListContent) => {
                    return formatDate(data.expirationAt);
                }}
            />

            <Column
                header={LABEL.common.status}
                align={"center"}
                body={(data: ListContent) => {
                    return createJobTag(data);
                }}
            />

            <Column
                header={LABEL.common.addDetail}
                align={"center"}
                body={(data: any) => { return renderDetailBtn(data) }}
            />
        </DataTable>
    );
};

export default JobsList;
