import { MESSAGE } from "../pages/constants";
import { SIZE_THUMBNAIL, TOPIC_FIELD_VALIDATE } from "../pages/topic-management/constants";

export default class ValidationUtils {
    static isValidFileSize = (file: any, size: number) => { // size unit is MB
        let check = true
        if (file && size) {
            let maxSize = size * 1024 * 1024;
            if (file.size > maxSize) {
                check = false
            }
        }
        return check
    }

    static getValidationMessage = (fieldName: string, lang: 'EN' | 'KR') => {
        const field = `${fieldName}${lang}`;
        return MESSAGE.validate.required.replace('{field}', TOPIC_FIELD_VALIDATE[field]);
    }

    static validateContentTopic = (content: any, lang: 'EN' | 'KR', isPopup: boolean) => {
        const errors = {} as any
        let conditions = [
            {
                field: 'title',
                condition: !content?.title,
                message: ValidationUtils.getValidationMessage('title', lang),
            },
            {
                field: 'description',
                condition: !content?.description,
                message: ValidationUtils.getValidationMessage('description', lang),
            },
            {
                field: 'thumbnail',
                condition: content?.thumbnail && !ValidationUtils.isValidFileSize(content?.thumbnail, SIZE_THUMBNAIL),
                message: MESSAGE.validate.thumbnail,
            }
        ];

        if (isPopup) {
            conditions.push(
                {
                    field: 'status',
                    condition: !content?.status,
                    message: ValidationUtils.getValidationMessage('status', lang),
                },
            );
            conditions.splice(1,1);
        }

        conditions.forEach((item) => {
            if (item?.condition) {
                errors[item.field] = item.message;
            }
        });

        return errors;
    }

    static isExpirationDate = (value: number) => {
        if (!value) return true;
        const _timestamp = value * 1000;
        const expirationDate = new Date(_timestamp);
        const currentDate = new Date();
        return currentDate > expirationDate
    }
}