import React, { useCallback, useEffect, useState } from "react";

import { format } from "date-fns";
import fileDownload from "js-file-download";
import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { TabMenu } from "primereact/tabmenu";
import Paging from "../../../components/common/Paging";
import { useApp } from "../../../hooks/useApp";
import { exportContact, filterContactList } from "../../../services/contactService";
import { ContactType } from "../../../types/ContactTypes";
import StringUtils from "../../../utils/stringUtils";
import { LABEL, REGEX_IS_NUMBER } from "../../constants";
import { DATE, LANG_TAB } from "../constants";
import TopicFilter from "./Filter";
import TopicList from "./List";

const ContactList: React.FC<any> = () => {

    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [data, setData] = useState<ContactType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<any>({});
    const [activeTab, setActiveTab] = useState<number>(0);
    const [pagingSpec, setPagingSpec] = useState<any>({});

    const app = useApp()

    const convertDataByPostCode=(data:ContactType[]) : ContactType[]=>{
       const arr:ContactType[]= data?.map((e:any) => {
            const postcode = e?.companyAddress?.split(" - ")??''; //get post code from first number at company address
            e.postCode= postcode[0]?.match(REGEX_IS_NUMBER)?postcode[0]:'';
            return e;
          });
          return arr;
    }

    const getData = useCallback(() => {
        let params: any = {
            ...query,
            page,
            size,
        };

        let newQuery = StringUtils.convertObjectToQueryString(params);
        filterContactList(newQuery)
            .then((res) => {
                if (res?.status === 200) {
                    let resData = res?.data;
                    const dataConvert = convertDataByPostCode(resData.contactDtos);
                    setData(dataConvert);
                    setTotal(resData.paginationSpec.totalElements);
                    setPagingSpec(resData.paginationSpec);
                }
            })
            .catch((error: any) => {
                let message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                });
            })
    }, [page, size, query]);

    const onReset = useCallback(() => {
        setPage(0);
        setSize(10);
        setQuery({});
        setActiveTab(0)
    }, [query]);

    const onFilter = useCallback((values: any) => {
        const params: any = {
            page: 0,
            size,
            ...values
        };
        params.createDateStart = params?.createDateStart ? format(params?.createDateStart, DATE.default) : null
        params.createDateEnd = params?.createDateEnd ? format(params?.createDateEnd, DATE.default) : null
        params.contactForm = params?.contactForm?.code || ''
        let newParams = StringUtils.clearParams(params)
        setQuery(newParams);
        setPage(0);
    }, [size, page]);

    const onChangeTab = useCallback((tab: any) => {
        setActiveTab(() => {
            let value = tab.value.value;
            let _query = {
                ...query,
                language: value === "all" ? '' : value || ''
            }
            setQuery(_query);
            return tab.index;
        });
    }, [query, page, size]);

    const exportData = useCallback(() => {
        let params: any = {
            ...query,
            page,
            size,
        };

        let _query = StringUtils.convertObjectToQueryString(params);
        exportContact(_query)
            .then((res: any) => {
                let status = res.status;
                if (status === 200) {
                    let date = format(new Date(), "yyyyMMdd");
                    let time = format(new Date(), "HHmmss");
                    let fileName = `Contacts-${date}-${time}.xlsx`
                    fileDownload(res?.data, fileName)
                }
            })
            .catch((error: any) => {
                let message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                });
            })
    }, [page, size, query]);

    useEffect(() => {
        getData();
    }, [query, page, size]);

    return (
        <>
            <p className="text-lg font-bold">{LABEL.contact.customerList}</p>
            <TopicFilter onFilter={onFilter} onReset={onReset} />
            <Fieldset legend={LABEL.common.listView} className="mt-3 w-fieldset">
                <div className="flex justify-content-between align-items-center mb-3">
                    <TabMenu
                        model={LANG_TAB}
                        onTabChange={onChangeTab}
                        activeIndex={activeTab}
                    />
                    <Button
                        label="다운로드"
                        icon="pi pi-file-export"
                        onClick={exportData}
                    />
                </div>
                <TopicList data={data} pagingSpec={pagingSpec} />
                <Paging
                    total={total}
                    currentPage={page}
                    rows={size}
                    onChangePage={(e: number) => setPage(e)}
                    onChangeSize={(e: number) => setSize(e)}
                />
            </Fieldset>
        </>
    );
};

export default ContactList;
