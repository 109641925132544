import React, { useCallback } from "react";

import { TopicType } from "../../../types/TopicTypes";
import { PATH, TYPE } from "../constants";


import { Column, ColumnBodyOptions } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import DetailBtn from "../../../components/common/DetailBtn";
import { LABEL } from "../../constants";

interface ListProps {
    data: TopicType[];
    pagingSpec: any;
}

const TopicList: React.FC<ListProps> = ({ data, pagingSpec }) => {
    const createType = useCallback((type: string) => {
        if (!type) return null;

        const _type = TYPE.filter((obj) => {
            return obj.code === type;
        })[0];

        return _type.name;
    }, []);

    const createTag = useCallback((status: string) => {
        if (!status) return null;

        let type: "warning" | "success" = "warning";
        let _status = LABEL.common.inActive
        if (status === "PUBLISH") {
            type = "success";
            _status = LABEL.common.active
        }

        return <Tag severity={type} value={_status} />;
    }, []);

    const renderDetailBtn = (data: TopicType) => {
        return <DetailBtn path={PATH.details} param={data?.boardPK?.boardId} />
    }

    return (
        <DataTable value={data}>
            <Column
                header="#"
                body={(data: TopicType, props: ColumnBodyOptions) => {
                    const index = props.rowIndex;
                    const page = pagingSpec.page;
                    const size = pagingSpec.size;

                    return page * size + index + 1;
                }}
            />
            <Column
                header={LABEL.common.id}
                body={(data: TopicType) => {
                    return data.boardPK.boardId
                }}
            />
            <Column field="subject" header={LABEL.common.title} />
            <Column
                header={LABEL.common.status}
                align={"center"}
                body={(data: TopicType) => {
                    return createTag(data.status);
                }}
            />
            <Column
                header={LABEL.topic.type}
                body={(data: TopicType) => {
                    return createType(data.typeCd);
                }}
            />
            <Column field="startDate" header={LABEL.topic.startDate} align={"center"} />
            <Column field="endDate" header={LABEL.topic.endDate} align={"center"} />
            <Column field="regId" header={LABEL.topic.registrant} />
            <Column
                header={LABEL.common.addDetail}
                align={"center"}
                body={(data: TopicType) => { return renderDetailBtn(data) }}
            />
        </DataTable>
    );
};

export default TopicList;
