import React, { useCallback, useContext, useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApp } from "../../../hooks/useApp";
import { getUserDetails, resetPassword, updateUser } from "../../../services/userService";
import { UserAdminType, UserType } from "../../../types/UserTypes";
import StringUtils from "../../../utils/stringUtils";
import { LABEL, MESSAGE, ROLES_ENUM } from "../../constants";
import { PATH, ROLES_OPTION, STATUS_OPTION } from "../constants";
import { AuthContext, AuthContextType } from "../../../contexts/AuthContext";

const ROLE_LABEL = {
    [ROLES_ENUM.ROLE_ADMIN]: 'ADMIN',
    [ROLES_ENUM.SUPER_ADMIN]: 'SUPER ADMIN'
} as any

const UserDetails: React.FC<any> = () => {
    const app = useApp()
    const { id } = useParams()

    const navigate = useNavigate();

    const [data, setData] = useState<UserType>()
    const { user } = useContext<AuthContextType>(AuthContext);

    const { handleSubmit, control, setValue, watch } = useForm({
        defaultValues: {
            email: "",
            fullName: "",
            role: [],
            status: {} as any,
            pass: '*****'
        },
    });

    useEffect(() => {
        if (id) {
            let _idQuery = StringUtils.convertObjectToQueryString({ email: id })
            getUserDetails(_idQuery)
                .then((res) => {
                    if (res?.status === 200) {
                        let data = res.data
                        let _status = STATUS_OPTION?.find((item: any) => item?.code === data?.accountLocked)
                        let _role = data?.role?.split(",") as []

                        setValue("fullName", data.fullName ?? '');
                        setValue("email", data.email ?? '');
                        setValue("status", _status ?? {});
                        setValue("role", _role ?? []);
                        setData(data);
                    }
                })
                .catch((error: any) => {
                    let message = error?.response?.data?.message || error?.message;
                    app.showToast({
                        type: 'error',
                        message: message
                    })
                })
        }
    }, [id])

    const onResetPassword = () => {
        if (!data?.email) return;
        let _value = {
            email: data?.email
        }
        resetPassword(_value)
            .then((res) => {
                if (res?.status === 200) {
                    app.showToast({
                        type: 'success',
                        message: res.data.message
                    })
                    navigate(PATH.index);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    }

    const submit = (values: any) => {
        let _fullName = values?.fullName?.trim() || ''

        if (_fullName && _fullName?.length > 255) {
            app.showToast({
                type: 'error',
                message: MESSAGE.validate.maxLengthFullName
            })
            return;
        }

        let _newValue = {
            ...data,
            accountLocked: values?.status?.code,
            fullName: _fullName || ''
        } as UserAdminType

        updateUser(_newValue)
            .then((res) => {
                if (res?.status === 200) {
                    app.showToast({
                        type: 'success',
                        message: res.data.message
                    })
                    navigate(PATH.index);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    };

    const watchFullName = watch("fullName");
    const watchStatus = watch("status");


    const isDisableSubmit = useCallback(() => {
        const _newFullName = watchFullName?.trim() !== data?.fullName
        const _newStatus = watchStatus?.code !== data?.accountLocked
        return !(_newFullName || _newStatus)
    }, [watchFullName, watchStatus]);

    return (
        <>
            <Fieldset legend={LABEL.common.viewInfo} className="w-fieldset">
                <form onSubmit={handleSubmit(submit)} className="flex flex-column align-items-center gap-8">
                    <div className="flex flex-column gap-5 lg:col-6 md:col-10 sm:col-12">
                        {/* Email */}
                        <div className="flex align-items-center gap-2 w-full">
                            <label
                                htmlFor="email"
                                className="min-w-label-3"
                            >
                                {LABEL.common.email}
                                <span className="text-red-600 pl-1">*</span>
                            </label>
                            <Controller
                                name="email"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                        disabled
                                    />
                                )}
                            />
                        </div>
                        {/* Email */}

                        {/* Fullname */}
                        <div className="flex align-items-center gap-2 w-full">
                            <label
                                htmlFor="fullName"
                                className="min-w-label-3"
                            >
                                {LABEL.common.fullName}
                            </label>
                            <Controller
                                name="fullName"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <InputText
                                        id={name}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        className={"w-full"}
                                    />
                                )}
                            />
                        </div>
                        {/* Fullname */}

                        {/* Role */}
                        <div className="flex align-items-start gap-2 w-full">
                            <label
                                htmlFor="role"
                                className="min-w-label-3"
                            >
                                {LABEL.user.role}
                            </label>
                            <Controller
                                name="role"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <div className="flex flex-column gap-3 ">
                                        {
                                            ROLES_OPTION?.map((role) => {
                                                let code = role?.code as never
                                                let checked = value?.includes(code)
                                                return (
                                                    <div className="flex gap-3 align-items-center" key={role?.code}>
                                                        <Checkbox
                                                            inputId={role?.code}
                                                            name={role?.name}
                                                            value={role}
                                                            onChange={onChange}
                                                            checked={checked}
                                                            disabled
                                                        />
                                                        <label htmlFor={role?.code} className="ml-2">
                                                            {ROLE_LABEL[code]}
                                                        </label>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>
                                )}
                            />
                        </div>
                        {/* Role */}

                        {/* Status */}
                        <div className="flex align-items-center gap-2 w-full">
                            <label
                                htmlFor="status"
                                className="min-w-label-3"
                            >
                                {LABEL.common.status}
                            </label>
                            <Controller
                                name="status"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <Dropdown
                                        id={name}
                                        value={value}
                                        options={STATUS_OPTION}
                                        optionLabel="name"
                                        onBlur={onBlur}
                                        onChange={(e: any) => onChange(e.value)}
                                        className={"w-full"}
                                        disabled={user?.email === data?.email}
                                    />
                                )}
                            />
                        </div>
                        {/* Status */}


                        {/* Password */}
                        <div className="flex align-items-center gap-2 w-full">
                            <label
                                htmlFor="pass"
                                className="min-w-label-3"
                            >
                                비밀번호
                            </label>
                            <Controller
                                name="pass"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <div className="flex gap-3 align-items-center w-full">
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            className={"w-full"}
                                            type="password"
                                            disabled
                                        />
                                        <Button
                                            severity="info"
                                            className="white-space-nowrap"
                                            type="button"
                                            label={LABEL.common.reset}
                                            onClick={onResetPassword}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        {/* Password */}

                    </div>

                    <div className="flex justify-content-end gap-5 lg:col-6 md:col-10 sm:col-12">
                        <Link to={PATH.index}>
                            <Button
                                label={LABEL.common.cancel}
                                type="button"
                                severity="secondary"
                            />
                        </Link>
                        <Button
                            label={LABEL.common.save}
                            type="submit"
                            disabled={isDisableSubmit()}
                        />
                    </div>
                </form>
            </Fieldset>
        </>
    );
};

export default UserDetails;
