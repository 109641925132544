import { Dialog } from 'primereact/dialog';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from 'react-router-dom';
import images from '../../../assets/images/images';
import Footer from '../../../components/common/Footer';
import { createNewPassword } from '../../../services/userService';
import { NewPasswordForm } from '../../../types/NewPasswordForm';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { MESSAGE } from '../../constants';

function ResetPassword() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [message, setMessage] = useState<string>('');
    const [displayMsg, setDisplayMsg] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const { control, handleSubmit, formState, watch } = useForm<NewPasswordForm>({
        defaultValues: {
            newPassword: '',
            verifyNewPassword: ''
        }
    });
    const { errors } = formState;

    const onSubmit = async (data: NewPasswordForm) => {
        const passwordToken = searchParams.get('passwordToken');
        createNewPassword(data, passwordToken)
            .then(response => {
                setMessage(response.data?.message);
                setDisplayMsg(true);
                setIsSuccess(true);
            })
            .catch(error => {
                if (error.response?.status === 500) {
                    setMessage(MESSAGE.error.system);
                } else {
                    error.response?.data ?
                        setMessage(error.response.data?.message)
                        : setMessage(MESSAGE.error.system);
                }
                setDisplayMsg(true);
                setIsSuccess(false);
            });


    };

    const handleCloseErrorPopup = () => {
        setDisplayMsg(false);
        setMessage('');
        if (isSuccess) {
            navigate("/auth/login");
        }
    }


    return (
        <>
            <Dialog visible={displayMsg} header="시스템 메시지" style={{ width: '30vw' }} onHide={() => handleCloseErrorPopup()}>
                <div className=" flex flex-row  m-0">
                    {isSuccess ?
                        <CheckBoxOutlinedIcon className="mr-4" sx={{ color: 'green', fontSize: 60 }}></CheckBoxOutlinedIcon> :
                        <WarningAmberIcon className="mr-4" sx={{ color: 'red', fontSize: 60 }} ></WarningAmberIcon>
                    }
                    <div className='mt-3'> {message}</div>

                </div>
            </Dialog>
            <div className="min-h-screen flex flex-column justify-content-between">
                <div className="w-full h-full text-center px-6 pt-6 flex flex-column ">
                    <img src={images.logo} className="h-4rem mt-8" alt="EVSIS" />

                    <div className="flex flex-column align-items-center gap-4 mt-8">
                        <div className="mb-3">
                            <h2>새로운 비밀번호 설정</h2>
                        </div>
                        <div className="flex flex-column gap-4">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="field grid w-30rem">
                                    <label htmlFor="newPassword" className="col-4 text-black-alpha-90 mb-0">
                                        <i className="pi pi-fw pi-lock mr-1 text-lg"></i>
                                        비밀번호 입력
                                    </label>
                                    <Controller name="newPassword" control={control}
                                        rules={{
                                            required: '비밀번호가 필요합니다. 확인하시고 재시도 부탁드립니다.',
                                            pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/, message: '비밀번호의 강도가 너무 약합니다!' }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Password
                                                id={field.name} {...field}
                                                type="password"
                                                maxLength={16}
                                                feedback={false}
                                                toggleMask
                                                className={classNames({ 'p-invalid': fieldState.error }, "w-20rem appearance-none outline-none")}
                                                inputClassName="w-20rem outline-none" inputStyle={{ padding: '0.5rem' }}
                                            />
                                        )}
                                    />
                                </div>
                                {errors.newPassword &&
                                    <div className="grid w-30rem">
                                        <div className="col-4"></div>
                                        <small className="col p-error text-left	">{errors.newPassword.message}</small>
                                    </div>
                                }
                                <div className="field grid w-30rem">
                                    <label htmlFor="verifyNewPassword" className="col-4 text-black-alpha-90 mb-0">
                                        <i className="pi pi-fw pi-lock mr-1 text-lg"></i>
                                        비밀번호 확인
                                    </label>
                                    <Controller name="verifyNewPassword" control={control}
                                        rules={{
                                            required: '비밀번호가 필요합니다. 확인하시고 재시도 부탁드립니다.',
                                            validate: (val: string) => {
                                                if (watch('newPassword') !== val) {
                                                    return '새 비밀번호와 일치하지 않습니다. 재시도해 주시기바랍니다.'
                                                }
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <Password
                                                id={field.name} {...field}
                                                type="password"
                                                maxLength={16}
                                                feedback={false}
                                                toggleMask
                                                className={classNames({ 'p-invalid': fieldState.error }, "w-20rem appearance-none outline-none")}
                                                inputClassName="w-20rem outline-none" inputStyle={{ padding: '0.5rem' }}
                                            />
                                        )}
                                    />
                                </div>
                                {errors.verifyNewPassword &&
                                    <div className="grid w-30rem">
                                        <div className="col-4"></div>
                                        <small className="col p-error text-left	">{errors.verifyNewPassword.message}</small>
                                    </div>
                                }
                                <div className="flex flex-wrap gap-2 justify-content-center">
                                    <button className="w-5 text-center block surface-400 text-900 font-semibold surface-border cursor-pointer border-round py-2"
                                        onClick={() => navigate("/auth/login")}>로그인 페이지로 이동</button>
                                    <button type='submit'
                                        className="w-5 text-center block surface-400 text-900 font-semibold surface-border cursor-pointer border-round py-2">적용</button>
                                </div>
                            </form>
                            <div className='text-left mt-4'>
                                <p className="font-bold	">비밀번호는 아래 기준을 따라야 합니다:</p>
                                <ul>
                                    <li className="mb-2">비밀번호는 8- 16자 이용.</li>
                                    <li className="mb-2">영대문자, 영소문자, 숫자, 특수문자 포함해야 합니다.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ResetPassword;
