
import images from "../../assets/images/images";

const Footer = () => {
    return (
        <div className='layout-footer'>
            <div className='footer-logo-container'>
                <img src={images.logo_lotte} alt="diamond-layout" />
                <span className='footer-copyright'>Copyright LDCC Viet Nam</span>
            </div>
        </div>
    );
};

export default Footer;
