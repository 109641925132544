import React, { useCallback, useEffect, useState } from "react";

import { Paginator } from "primereact/paginator";

interface PagingProps {
    total: number;
    currentPage: number;
    rows: number;
    onChangePage: (value: number) => void;
    onChangeSize: (value: number) => void;
}

type PagingEvent = {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
};

const Paging: React.FC<PagingProps> = ({
    total,
    currentPage,
    rows,
    onChangePage,
    onChangeSize,
}) => {
    const [first, setFirst] = useState(0);

    useEffect(() => {
        setFirst(currentPage * rows)
    }, [currentPage,rows ])

    const onPageChange = useCallback(
        (event: PagingEvent) => {
            setFirst(event.first);
            onChangePage(event.page);
            onChangeSize(event.rows);
        },
        [onChangePage]
    );

    if (!total) return null;

    return (
        <Paginator
            first={first}
            rows={rows}
            totalRecords={total}
            rowsPerPageOptions={[10, 50, 100]}
            onPageChange={onPageChange}
            className={"mt-4"}
        />
    );
};

export default Paging;
