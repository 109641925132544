export const MESSAGE = {
    enableEdit: "편집모드입니다.",
    chooseLanguage:'언어선택해주세요.',
    validate: {
        inValidStartEndDate: '시작일은 종료일보다 앞서야 합니다. 다시 시도해 주세요.',
        mobilePhone: "전화번호가 숫자만 (0~9) 적용합니다.",
        telephone: "전화번호가 숫자만 (0~9) 적용합니다.",
        required: '{field} 필수 입력 사항입니다. 다시 시도해 주세요.',
        thumbnail: '업로드한 이미지가 너무 큽니다. 5MB 이하의 이미지를 업로드해주세요.',
        maxLengthFullName: '이름의 제한은 255자입니다.'
    },
    format: {
        mobilePhone: "전화번호가 숫자만 (0~9) 적용합니다.",
        telephone: "전화번호가 숫자만 (0~9) 적용합니다.",
        email: "이메일 형식이 올바르지 않습니다.",
    },
    error: {
        system: "시스템에서 미예정한 오류가 발생했습니다. 잠시 기다리면서 재시도 부탁드립니다.",
        unAuthorized: '접속 권한이 없습니다',
        pageNotFound: '요청하신 페이지를 찾을 수 없습니다',
        accessDenied: '접근 불가입니다',
    },
    success: {
        edit: '상태가 변경되었습니다.'
    },
}

export const LABEL = {
    common: {
        viewDetail: '조회 내용',
        title: '제목',
        status: '상태',
        addNew: '신규 등록',
        address: '주소',
        addDetail: '더보기',
        contact: 'Contact us',
        payment: '충전기 문의',
        save: '저장',
        cancel: '취소',
        upload: '업로드',
        back: '뒤로',
        email: '이메일',
        all: '전체',
        detail: '상세 내용',
        edit: '수정',
        createDate: '등록일',
        id: '아이디',
        reset: '초기화',
        search: '조회',
        listView: '고객 연락처 명단',
        language: '언어',
        active: '게시',
        inActive: '미게시',
        getData: '데이터 가져오기',
        list: '리스트',
        fullName: '이름',
        logOut: '로그 아웃',
        viewInfo:'상세한 정보',
        updateDate:'수정일'
    },
    status: {
        public: '게시',
        unPublic: '미게시',
    },
    contact: {
        telephone: '유선전화',
        mobilePhone: '휴대전화',
        position: '직위',
        apartment: "아파트",
        officetel: '오피스텔',
        building: '상가',
        office: '관공서',
        other: '기타',
        buildingType: '시설구분',
        postCode: '우편번호',
        expectedQuantity: '희망수량',
        preInstallQuantity: '기설치 충전기 수량',
        parkingStatus: '주차면현황',
        attachment: '첨부',
        comment: '요구사항',
        fullName: '성명',
        company: '신청기관명',
        country: '국가',
        formType: '분류',
        customerList: '고객 연락처 목록'
    },
    topic: {
        type: '유형',
        sortOnTop: '맨 위로 설정',
        startDate: '시작일',
        endDate: '종료일',
        titleKR: '제목 (KR)',
        titleEN: '제목 (EN)',
        descriptionKR: '내용 (KR)',
        descriptionEN: '내용 (EN)',
        statusKR: '상태 (KR)',
        statusEN: '상태 (EN)',
        thumbnailKR: '썸네일 이미지 (KR)',
        thumbnailEN: '썸네일 이미지 (EN)',
        hashTagsKR: '해시태그(KR)',
        hashTagsEN: '해시태그(EN)',
        period: ' 기간',
        registrant: '등록자',
        generalInfo:'1. 게시글 공통 정보',
        descriptionInfo:'2. 게시글 상세한 정보',
        category: '카테고리'
    },
    job: {
        detail: '채용 상세 정보',
        id: '공고 아이디',
        title: '공고 제목',
        expirationAt: '마감일',
        createdById: '등록자',
        description: '업무 설명서',
        information: '에서 호출된 채용정보',
        recruitmentInfo: '채용정보',
        inputId: '공고 아이디 입력',
        registrant: '등록자',
        expired: '마감상태'
    },
    qna: {
        answer: '답변',
        detail: 'Q&A내역',
        id: 'Q&A번호',
        subject: '제목',
        regId: '회원번호',
        regDTime: '작성일',
        status: '진행상태',
        hidden: '비공개',
        title: '제목',
        progress: '진행상태',
        memberId: '회원번호',
        period: '작성일',
        list: 'Q&A목록'
    },
    account: {
        active: '활성',
        unActive: '비활성'
    },
    user: {
        list: "사용자 리스트",
        role: '권한',
        admin: 'Admin',
        supperAdmin: 'Super admin',
        editUser: '정보 변경'
    },
    menu: {
        changePassword: "비밀번호 변경",
        myAccount: "나의 계정",
        system: "시스템 관리",
        user: "사용자관리",
        company: "채용 관리",
        job: "채용정보",
        content: "콘텐츠 관리",
        qnA: "문의 관리",
        contact: "고객 연락처 목록",
        topic: "소식 관리",
    }
}

export const ALL_OPTION = {
    name: LABEL.common.all,
    code: "",
}

export const AREA_TEL_NUMBER = [
    '02',
    '010',
    '011',
    '016',
    '017',
    '019',
    '0303',
    '031',
    '032',
    '033',
    '041',
    '042',
    '043',
    '044',
    '0502',
    '0505',
    '051',
    '052',
    '053',
    '054',
    '055',
    '061',
    '062',
    '063',
    '064',
    '070',
]

export const AREA_MOBILE_NUMBER = [
    '010',
    '011',
    '016',
    '017',
    '019',
]

export const REGEX_IS_NUMBER = /^[0-9]+$/;

export const FORMAT_MOBILE_PHONE = /^[0-9]+$/;
export const FORMAT_TELEPHONE = /^[0-9]+$/;
export const DATE_FORMAT = "yyyy-MM-dd"

export const FORMAT_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const ROLES_ENUM = {
    SUPER_ADMIN: "SUPER_ADMIN",
    ROLE_ADMIN: "ROLE_ADMIN",
};