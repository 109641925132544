import axiosInstance from "./axiosConfig";
import { LoginRequest } from "../types/LoginRequest";

//Login
export function login(data: LoginRequest) {
    return axiosInstance.post(`/api/public/login`, data);
}

export const logout = () => {
    return axiosInstance.get(`/api/public/logout`);
};
