import React, {
    BaseSyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { Controller } from "react-hook-form";

import { Button } from "primereact/button";

import images from "../../../assets/images/images";
import { useApp } from "../../../hooks/useApp";

interface ThumbnailProps {
    className?: string;
    isEdit: boolean;
    data?: string;
    formProps?: any;
}

const ThumbnailInput: React.FC<ThumbnailProps> = ({
    isEdit,
    data,
    className,
    formProps,
}) => {
    const inputRef = useRef<any>(null);
    const app = useApp()

    const { control, name, setValue } = formProps;

    const [selectedFile, setSelectedFile] = useState<any>(null);
    const [preview, setPreview] = useState<any>(null);

    const onUpload = (event: BaseSyntheticEvent) => {
        if (!event.target.files || event.target.files.length === 0) {
            setSelectedFile(null);
            return;
        }

        setSelectedFile(event.target.files[0]);
    };

    const onClearFile = useCallback(() => {
        setSelectedFile(null);
        setPreview(null);
        setValue(name, "");
        inputRef.current.value = null;
    }, [setSelectedFile, setPreview, setValue, inputRef]);

    const createUploadBtn = useMemo(() => {
        return (
            <div className="relative">
                <Controller
                    name={name}
                    control={control}
                    render={({ field: { name, onChange } }) => (
                        <input
                            name={name}
                            type={"file"}
                            className="absolute top-0 left-0 right-0 bottom-0 z-1 cursor-pointer opacity-0"
                            onChange={(e) => {
                                const file: FileList | null =
                                    e.target.files ?? null;
                                if (file) {
                                    const _file: File = file[0];
                                    const size = Math.round(_file.size / 1024);
                                    if (size >= 5120) {
                                        app.showToast({
                                            type: "error",
                                            message: "업로드한 이미지가 너무 큽니다. 5MB 이하의 이미지를 업로드해주세요.",
                                        });
                                        return;
                                    }
                                    onUpload(e);
                                    onChange(file[0]);
                                }
                            }}
                            ref={inputRef}
                        />
                    )}
                />
                {isEdit && !preview && (
                    <Button
                        label="업로드"
                        severity="secondary"
                        type="button"
                        icon="pi pi-upload"
                    />
                )}
            </div>
        );
    }, [name, control, inputRef, isEdit, preview]);

    const createRemoteBtn = useMemo(() => {
        if (!isEdit) return null;

        if (!preview) return null;

        return (
            <Button
                severity="danger"
                type="button"
                icon="pi pi-times"
                onClick={() => onClearFile()}
            />
        );
    }, [preview, onClearFile, isEdit]);

    useEffect(() => {
        if (!selectedFile) {
            setPreview(null);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    // initital values
    useEffect(() => {
        if (data) {
            if (data.includes("data:;base64")) {
                setPreview(data);
            }
            else {
                setPreview("data:;base64," + data)
            }
        }
    }, [data]);

    return (
        <div className={`flex align-items-center justify-content-between gap-2 w-full overflow-hidden ${className ?? ''}`}>
            <div className="w-4">
                <img src={preview || images.placeholder} className={"w-full"} />
            </div>
            {createUploadBtn}
            {createRemoteBtn}
        </div>
    );
};

export default ThumbnailInput;
