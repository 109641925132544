import { LABEL } from "../constants";

export const STATUS = [
    { name: LABEL.common.active, code: "PUBLISH" },
    { name: LABEL.common.inActive, code: "UNPUBLISH" },
];

export const TOPIC_TYPE_ENUM = {
    'news': "NW",
    'notice': 'NT',
    'faq': 'FQ',
    'event': 'EV',
    'popup': 'PO'
};

export const TYPE = [
    { name: "뉴스", code: "NW" },
    { name: "공지사항", code: "NT" },
    { name: "FAQ", code: "FQ" },
    { name: "이벤트", code: "EV" },
    { name: "팝업", code: "PO" },
];

export const LANG_TAB = [
    { label: "All", value: "all" },
    { label: "Korean", value: "KR" },
    { label: "English", value: "EN" },
];

export const PATH = {
    index: "/topic-management",
    details: "/topic-management/details",
    new: "/topic-management/new",
};

export const DATE = {
    default: "yyyy-MM-dd",
};

export const SIZE_THUMBNAIL = 5

export const TOPIC_FIELD_VALIDATE = {
    titleKR: '제목 (KR)',
    titleEN: '제목 (EN)',
    descriptionKR: '내용 (KR)',
    descriptionEN: '내용 (EN)',
    statusKR: '상태 (KR)',
    statusEN: '상태 (EN)',
} as any

export const CATEGORIES_TYPE = [
    { name: "가입", code: "0" },
    { name: "충전/이용", code: "1" },
    { name: "결제", code: "2" },
    { name: "충전소 찾기", code: "3" },
    { name: "기타", code: "4" },
];