import React, { useCallback, useEffect, useState } from "react";

import { format } from "date-fns";

import { filterQAList } from "../../../services/qaService";
import { QAType } from "../../../types/QATypes";
import { DATE } from "../constants";

import { Fieldset } from "primereact/fieldset";
import Paging from "../../../components/common/Paging";
import { useApp } from "../../../hooks/useApp";
import StringUtils from "../../../utils/stringUtils";
import { LABEL } from "../../constants";
import QAFilter from "./Filter";
import QAList from "./List";

const QAManagement: React.FC<any> = () => {
    const app = useApp()

    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [data, setData] = useState<QAType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<any>({});
    const [pagingSpec, setPagingSpec] = useState<any>({});

    const getList = useCallback(() => {
        let params: any = {
            ...query,
            page,
            size,
        };

        let newQuery = StringUtils.convertObjectToQueryString(params)

        filterQAList(newQuery)
            .then((res: any) => {
                if (res?.status === 200) {
                    let data = res.data;
                    setData(data?.content);
                    setTotal(data?.paginationSpec?.totalElements);
                    setPagingSpec(data?.paginationSpec);
                }
            })
            .catch((error: any) => {
                let message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: "error",
                    message: message
                })
            })
    }, [page, size]);

    const onFilter = useCallback((values: any) => {
        setPage(0);

        const params: any = {
            page: 0,
            size,
        };

        const qaID = values.qaID;
        if (qaID) {
            params.qnaId = qaID;
        }

        const qaTitle = values.qaTitle;
        if (qaTitle) {
            params.subject = qaTitle;
        }

        const memberID = values.memberID;
        if (memberID) {
            params.regId = memberID;
        }

        const status = values.status;
        if (status && Object.keys(status).length) {
            const code = status.code;

            if (code && code !== "all") {
                params.status = code;
            }
        }

        const from = values.from;
        if (from) {
            params.startDate = format(from, DATE.default);
        }

        const to = values.to;
        if (to) {
            params.endDate = format(to, DATE.default);
        }

        setQuery(params);
    }, [size]);

    const onReset = useCallback(() => {
        setPage(0);
        setSize(10);
        setQuery({});
        getList();
    }, [query]);

    const filterData = useCallback(() => {
        let lang = query.lang;
        if (lang && lang === "all") {
            delete query.lang;
        }

        let _query = StringUtils.convertObjectToQueryString(query)
        filterQAList(_query)
            .then((res: any) => {
                if (res?.status === 200) {
                    let data = res.data;
                    setData(data?.content);
                    setTotal(data?.paginationSpec?.totalElements);
                    setPagingSpec(data?.paginationSpec);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: "error",
                    message: message
                })
            })
    }, [query]);

    useEffect(() => {
        getList();
    }, [page, size, getList]);

    useEffect(() => {
        if (Object.keys(query).length) {
            filterData();
        }
    }, [query]);

    return (
        <>
            <p className="text-lg font-bold">문의 관리</p>
            <QAFilter onFilter={onFilter} onReset={onReset} />
            <Fieldset legend={LABEL.qna.list} className="mt-3 w-fieldset">
                <QAList data={data} pagingSpec={pagingSpec} />
                <Paging
                    total={total}
                    currentPage={page}
                    rows={size}
                    onChangePage={(e: number) => setPage(e)}
                    onChangeSize={(e: number) => setSize(e)}
                />
            </Fieldset>
        </>
    );
};

export default QAManagement;
