import React, { useCallback, useEffect, useState } from "react";

import { format } from "date-fns";

import { filterTopicList } from "../../../services/topicService";
import { TopicType } from "../../../types/TopicTypes";
import { DATE, LANG_TAB, PATH } from "../constants";

import { Link } from "react-router-dom";

import { Button } from "primereact/button";
import { Fieldset } from "primereact/fieldset";
import { TabMenu } from "primereact/tabmenu";

import Paging from "../../../components/common/Paging";

import { useApp } from "../../../hooks/useApp";
import StringUtils from "../../../utils/stringUtils";
import { LABEL } from "../../constants";
import TopicFilter from "./Filter";
import TopicList from "./List";

const TopicManagement: React.FC<any> = () => {
    const app = useApp()

    const [page, setPage] = useState<number>(0);
    const [size, setSize] = useState<number>(10);
    const [data, setData] = useState<TopicType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [query, setQuery] = useState<any>({});
    const [activeTab, setActiveTab] = useState<number>(0);
    const [pagingSpec, setPagingSpec] = useState<any>({});

    const getList = useCallback(() => {
        let params: any = {
            ...query,
            page,
            size,
        };

        let queryNew = StringUtils.convertObjectToQueryString(params)
        filterTopicList(queryNew)
            .then((res: any) => {
                if (res.status === 200) {
                    let result = res?.data
                    setData(result?.boardDto);
                    setTotal(result?.paginationSpec?.totalElements);
                    setPagingSpec(result?.paginationSpec);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })

    }, [page, size]);

    const onReset = useCallback(() => {
        setPage(0);
        setSize(10);
        setQuery({});
        getList();
        setActiveTab(0)
    }, [query]);

    const onFilter = useCallback(
        (values: any) => {
            const params: any = {
                page: 0,
                size,
            };

            const topicId = values.topicId;
            if (topicId) {
                params.boardId = topicId;
            }

            const topicTitle = values.topicTitle;
            if (topicTitle) {
                params.subject = topicTitle;
            }

            const status = values.status;
            if (status && Object.keys(status).length) {
                const code = status.code;

                if (code && code !== "all") {
                    params.status = code;
                }
            }

            const type = values.type;
            if (type && Object.keys(type).length) {
                const code = type.code;

                if (code && code !== "all") {
                    params.typeCd = type.code;
                }
            }

            const from = values.from;
            if (from) {
                params.startDate = format(from, DATE.default);
            }

            const to = values.to;
            if (to) {
                params.endDate = format(to, DATE.default);
            }

            setQuery(params);
            setPage(0);
        },
        [size]
    );

    const onChangeTab = useCallback(
        (tab: any) => {
            setActiveTab(() => {
                const value = tab.value.value;
                const _query = { ...query, page: 0, size };
                if (value) {
                    _query.lang = value;
                }
                setQuery(_query);
                return tab.index;
            });
        },
        [setActiveTab, query, setQuery, page, size]
    );

    const filterData = useCallback(() => {
        let lang = query.lang;

        if (lang && lang === "all") {
            delete query.lang;
        }
        let _query = StringUtils.convertObjectToQueryString(query)
        filterTopicList(_query)
            .then((res: any) => {
                const status = res.status;
                if (status === 200) {
                    let result = res?.data
                    setData(result?.boardDto);
                    setTotal(result?.paginationSpec?.totalElements);
                    setPagingSpec(result?.paginationSpec);
                }
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            })
    }, [query]);

    useEffect(() => {
        getList();
    }, [page, size]);

    useEffect(() => {
        if (Object.keys(query).length) {
            filterData();
        }
    }, [query]);

    return (
        <>
            <p className="text-lg font-bold">소식 관리</p>
            <TopicFilter onFilter={onFilter} onReset={onReset} />
            <Fieldset legend={LABEL.common.list} className="mt-3 w-fieldset">
                <div className="flex justify-content-between align-items-center mb-3">
                    <TabMenu
                        model={LANG_TAB}
                        onTabChange={onChangeTab}
                        activeIndex={activeTab}
                    />
                    <Link to={PATH.new}>
                        <Button label={LABEL.common.addNew} icon="pi pi-plus" />
                    </Link>
                </div>
                <TopicList data={data} pagingSpec={pagingSpec} />
                <Paging
                    total={total}
                    currentPage={page}
                    rows={size}
                    onChangePage={(e: number) => setPage(e)}
                    onChangeSize={(e: number) => setSize(e)}
                />
            </Fieldset>
        </>
    );
};

export default TopicManagement;
