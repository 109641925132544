import React, { BaseSyntheticEvent, useCallback } from "react";

import { Controller, useForm } from "react-hook-form";

import { ROLES_OPTION, STATUS_OPTION } from "../constants";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { ALL_OPTION, LABEL } from "../../constants";

type FilterValues = {
    fullName: string;
    userID: string;
    role: string;
    status: string;
};

interface FilterProps {
    onFilter: (values: FilterValues) => void;
    onReset: () => void;
}

const UserFilter: React.FC<FilterProps> = ({ onFilter, onReset }) => {
    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            fullName: "",
            userID: "",
            role: ALL_OPTION,
            status: ALL_OPTION,
        },
    });

    const onFilterReset = useCallback(
        (event: BaseSyntheticEvent) => {
            event.preventDefault();
            reset();

            if (typeof onReset === "function") {
                onReset();
            }
        },
        [reset, onReset]
    );

    const submit = (values: any) => {
        if (typeof onFilter !== "function") return;

        onFilter(values);
    };

    return (
        <>
            <form onSubmit={handleSubmit(submit)}>
                <Fieldset legend="조회내용" className="w-fieldset">
                    <div className="flex flex-wrap">
                        {/* fullName */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-6 sm:col-12">
                            <Controller
                                name="fullName"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.common.fullName}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        {/* fullName */}

                        {/* userID */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-6 sm:col-12">
                            <Controller
                                name="userID"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.common.id}
                                        </label>
                                        <InputText
                                            id={name}
                                            value={value}
                                            onBlur={onBlur}
                                            onChange={(e: any) => onChange(e)}
                                            className={"w-full"}
                                        />
                                    </>
                                )}
                            />
                        </div>
                        {/* userID */}

                        {/* status */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-6 sm:col-12">
                            <Controller
                                name="status"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.common.status}
                                        </label>
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={[
                                                ALL_OPTION,
                                                ...STATUS_OPTION,
                                            ]}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className="w-full"
                                        />
                                    </>
                                )}
                            />
                        </div>
                        {/* status */}

                        {/* role */}
                        <div className="flex align-items-center gap-2 lg:col-3 md:col-6 sm:col-12">
                            <Controller
                                name="role"
                                control={control}
                                render={({
                                    field: { name, value, onBlur, onChange },
                                }) => (
                                    <>
                                        <label
                                            htmlFor={name}
                                            className="min-w-label-1"
                                        >
                                            {LABEL.user.role}
                                        </label>
                                        <Dropdown
                                            id={name}
                                            value={value}
                                            options={[
                                                ALL_OPTION,
                                                ...ROLES_OPTION,
                                            ]}
                                            optionLabel="name"
                                            onBlur={onBlur}
                                            onChange={(e: any) =>
                                                onChange(e.value)
                                            }
                                            className="w-full"
                                        />
                                    </>
                                )}
                            />
                        </div>
                        {/* role */}
                    </div>

                    <div className="flex justify-content-end gap-3 col-12 mt-2">
                        <Button
                            severity="secondary"
                            type="button"
                            icon="pi pi-refresh"
                            label={LABEL.common.reset}
                            onClick={(e) => onFilterReset(e)}
                        />
                        <Button
                            label={LABEL.common.search}
                            icon="pi pi-search"
                            type="submit"
                        />
                    </div>
                </Fieldset>
            </form>
        </>
    );
};

export default UserFilter;
