import React, { useCallback, useMemo, useState } from 'react';
import { LayoutContextType, LayoutStateType } from '../types/Layout';

export const LayoutContext = React.createContext<LayoutContextType>({} as LayoutContextType);
export const LayoutProvider = (props: any) => {
    const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
    const [layoutState, setLayoutState] = useState<LayoutStateType>({
        staticMenuDesktopInactive: false,
        staticMenuMobileActive: false,
        resetMenu: false,
    });

    const isDesktop = useCallback(() => {
        return window.innerWidth > 991;
    }, []);

    const onMenuToggle = useCallback((event: any) => {
        if (isDesktop()) {
            setLayoutState((prevLayoutState) => ({
                ...prevLayoutState,
                staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive
            }));
        } else {
            setLayoutState((prevLayoutState) => ({
                ...prevLayoutState,
                staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive
            }));
            event.preventDefault();
        }
    }, [isDesktop]);

    const layoutContext = useMemo(() => {
        return {
            layoutState,
            setLayoutState,
            isDesktop,
            onMenuToggle,
            breadcrumbs,
            setBreadcrumbs
        };
    }, [layoutState, setLayoutState, isDesktop, onMenuToggle, breadcrumbs, setBreadcrumbs]);

    return (
        <LayoutContext.Provider value={layoutContext}>
            {props.children}
        </LayoutContext.Provider>
    );
};
