import { Button } from 'primereact/button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type DetailBtnProps = {
    path: string;
    param: any;
};

const DetailBtn: React.FC<DetailBtnProps> = ({ path, param }) => {
    const navigate = useNavigate()

    const onShowDetail = () => {
        navigate(`${path}/${param}`);
    }

    return (
        <Button
            severity="success"
            type="button"
            icon="pi pi-external-link"
            onClick={onShowDetail}
        />
    );
};

export default DetailBtn;