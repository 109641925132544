import axiosInstance from "./axiosConfig";

export const getContactList = (query: string) => {
    return axiosInstance.get(`/api/admin/contacts${query ?? ""}`);
};

export const filterContactList = (query: string) => {
    return axiosInstance.get(
        `/api/admin/contact/filter${query ?? ''}`
    );
};

export const getContactDetails = (id: string) => {
    return axiosInstance.get(`/api/admin/contact/${id}`);
};

export const updateContact = (data: any) => {
    return axiosInstance.put(`/api/admin/contact/update`, data);
};

export const exportContact = (query: string) => {
    return axiosInstance.get(
        `/api/admin/contact/export${query ?? ""}`, { responseType: "blob" }
    );
};