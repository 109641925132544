import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Authorize from './components/common/Authorize';
import PageNotFound from './components/common/PageNotFound';
import ForgotPassword from './pages/auth/forgotpassword';
import Login from './pages/auth/login';
import ResetPassword from './pages/auth/resetpassword';
import ContactDetails from './pages/contact/details';
import ContactList from './pages/contact/list';
import Home from './pages/home';
import JobDetails from './pages/job/details';
import JobList from './pages/job/list';
import ChangePassword from './pages/myaccount/changepassword';
import News from './pages/news';
import QADetails from './pages/qna/details';
import QAManagement from './pages/qna/list';
import TopicDetails from './pages/topic-management/details';
import TopicManagement from './pages/topic-management/list';
import TopicNew from './pages/topic-management/new';
import UserDetails from './pages/user/details';
import UserManagement from './pages/user/list';
import UserNew from './pages/user/new';
import './styles/layout/layout.scss';

const DefaultLayout = lazy(() => import('./components/common/layout'));

function App() {

  return (
    <>
      <Routes>
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgot-password" element={<ForgotPassword />} />
          <Route path="/auth/reset-password" element={<ResetPassword />} />
          <Route element={<DefaultLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/news" element={<News />} />
            <Route path="/topic-management" element={<TopicManagement />} />
            <Route path="/topic-management/new" element={<TopicNew />} />
            <Route path="/topic-management/details/:id" element={<TopicDetails />} />
            <Route path="/job" element={<JobList />} />
            <Route path="/job/details/:id" element={<JobDetails />} />
            <Route path="/qa" element={<QAManagement />} />
            <Route path="/qa/details/:id" element={<QADetails />} />
            <Route path="/contact" element={<ContactList />} />
            <Route path="/contact/details/:id" element={<ContactDetails />} />
            <Route
              path="/user"
              element={
                <Authorize mode='access' hasPermission>
                  <UserManagement />
                </Authorize>
              }
            />

            <Route
              path="/user/new"
              element={
                <Authorize mode='access' hasPermission>
                  <UserNew />
                </Authorize>

              }
            />

            <Route
              path="/user/details/:id"
              element={
                <Authorize mode='access' hasPermission>
                  <UserDetails />
                </Authorize>
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </>
  );
}

export default App;
