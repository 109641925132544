import React, { useCallback, useContext, useEffect, useState } from "react";

import { changeUserStatus } from "../../../services/userService";
import { UserType } from "../../../types/UserTypes";

import { InputSwitch } from "primereact/inputswitch";
import { AuthContext, AuthContextType } from "../../../contexts/AuthContext";
import { useApp } from "../../../hooks/useApp";

interface ListSwitchProps {
    data: UserType;
    onUpdate: () => void;
}

const ListSwitch: React.FC<ListSwitchProps> = ({ data, onUpdate }) => {
    const status = data.accountLocked || false;
    const email = data.email;

    const { user } = useContext<AuthContextType>(AuthContext);

    const app = useApp()

    const [checked, setChecked] = useState<any>(false);

    const onChangeUserStatus = useCallback(
        async (checked: any) => {
            setChecked(checked);
            try {
                await changeUserStatus(email).then((res) => {
                    const status = res.status;

                    if (status === 200 && typeof onUpdate === "function") {
                        onUpdate();
                    }
                });
            } catch (error: any) {
                const message =
                    error?.response?.data?.message || error?.message;
                app.showToast({
                    type: 'error',
                    message: message
                })
            }
        },
        [email, onUpdate]
    );

    useEffect(() => {
        setChecked(!status);
    }, [status]);

    return (
        <div style={{ minWidth: '45px' }}>
            {
                user?.email !== email &&
                <InputSwitch
                    checked={checked}
                    onChange={(e) => onChangeUserStatus(e.value)}
                />
            }
        </div>
    );
};

export default ListSwitch;
