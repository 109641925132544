export const PATH = {
    index: "/qa",
    details: "/qa/details",
};

export const STATUS = [
    { name: "문의완료", code: "ASKED" },
    { name: "답변완료", code: "REPLIED" },
];

export const DATE = {
    default: "yyyy-MM-dd",
};
