import axios from 'axios';
import Cookies from "universal-cookie";

let language = window.navigator.language;
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: {
        "Content-type": "application/json",
        "Accept-Language": `${language}`
    }
});

//set Authorization when logged in
instance.interceptors.request.use(function (config) {
    const cookies = new Cookies(null);
    const token = cookies.get("user")?.jwt;

    if (token === undefined) {
        config.headers.Authorization = '';
    } else {
        config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
});

instance.interceptors.response.use(
    (response) => {
        return Promise.resolve(response)
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            const _token = error?.config?.headers?.Authorization
            if (_token) {
                window.location.href = '/auth/login'
            }
        }
        return Promise.reject(error)
    }
);

export default instance;